import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private loggedIn = false;

  constructor(private auth: AuthService) {
    this.auth.isLoggedIn.subscribe(signin => {
      this.loggedIn = typeof signin === 'boolean' ? <boolean>signin : false;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request: Observable<HttpEvent<any>> = this.loggedIn
      ? next.handle(req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.auth.authToken) }))
      : next.handle(req);

    return request.pipe(
      catchError(err => {
        if (err.status === 403 || err.status === 401) {
          this.auth.logout();
        }
        return throwError(err);
      })
    );
  }
}
