import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';
import { DynamicControl } from '.';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  styleUrls: ['./dynamic-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicControlComponent),
      multi: true
    }
  ]
})
export class DynamicControlComponent implements OnInit, ControlValueAccessor {
  @Input() control: DynamicControl<any>;
  @Input() form: FormGroup;
  @Input() formControlName: string;

  constructor() {}

  ngOnInit() {}

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn): void {
    this.form.valueChanges.subscribe(_ => {});
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.form.disable();
    else this.form.enable();
  }
}
