<div class="container">
  <div class="row">
    <div class="col-md-6 col-12">
      <h3 class="headings">ADVERTISE WITH US</h3>
    </div>
  </div>
</div>
<img src="assets/img/shutterstock_643722736-Edited-Narrow.jpg" />

<div class="container mt-5 mb-5">
  <div class="row">
    <div class="col">
      <h4 class="text-center">Advertise with Agrichecker</h4>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h1 class="text-center"><strong>Download our rate card</strong></h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <p>
        Expand your brand. Find your people. Reach new audiences. Agrichecker offers a range of digital advertising
        opportunities on a variety of platforms to help your business grow. Click the link below to find out more.
      </p>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <a target="_blank" [href]="rateCard"><button class="btn ac-btn-primary mt-2 col-md-3 col-6">DOWNLOAD</button></a>
    </div>
  </div>
</div>
