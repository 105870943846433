import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IProduct,
  ProductAttribute,
  ProductStatus,
  ProductType,
  ProductUtil,
  ProductSummary,
  ImageSize,
  NotificationService
} from 'core';
import { CartService } from '../../checkout/cart.service';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss']
})
export class ProductSummaryComponent implements OnInit {
  private _prod: IProduct;
  private summary: ProductSummary;

  buttons = {
    shop: {
      icon: 'fa-shopping-cart',
      label: 'BUY'
    },
    classified: {
      icon: 'fa-eye',
      label: 'VIEW'
    },
    quote: {
      icon: 'fa-edit',
      label: 'QUOTE'
    }
  };

  private getAttribute = ProductUtil.getAttribute;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cart: CartService,
    private products: ProductService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  @Input()
  set product(val: IProduct) {
    if (val) {
      this._prod = val;
      this.summary = new ProductSummary(this._prod);
    }
  }

  get product() {
    return this._prod;
  }

  edit() {
    this.router.navigate(['edit', this.product.id], { relativeTo: this.route });
  }

  navigate() {
    if (this.button === this.buttons.classified) {
      this.router.navigate([this.product.id], { relativeTo: this.route });
    } else {
      this.cart.add(this.product);
    }
  }

  disabledInfo() {
    this.notificationService.openSnackbar({
      data: {
        type: 'info',
        message: this.cart.canAdd(this.product).message
      }
    });
  }

  get product_image() {
    return this.product && this.product.image ? this.products.getProductImage(this.product.id, ImageSize.small) : false;
  }

  get merchant() {
    return this.product.merchant ? this.product.merchant.name : '';
  }

  get price() {
    if (!this.isQuote && this.product.price.value && this.product.price.value.price_excl_vat) {
      return this.product.price.value.price_excl_vat;
    }
    return 'Request Price';
  }

  get price_unit() {
    if (this.product.unit_size) return '/ ' + this.product.unit_size + this.product.unit;
  }

  get attribute1(): ProductAttribute | string {
    return this.getAttribute(this.product, 0);
  }

  get attribute2(): ProductAttribute | string {
    return this.getAttribute(this.product, 1);
  }

  get button() {
    if (this.product.product_type === ProductType.classified) {
      return this.buttons.classified;
    }
    if (this.product.product_type === ProductType.shop) {
      return this.buttons.shop;
    }
    if (this.product.product_type === ProductType.quote) {
      return this.buttons.quote;
    }
    return this.buttons.classified;
  }

  get isShop() {
    return this.product.product_type === ProductType.shop;
  }

  get isQuote() {
    return this.product.product_type === ProductType.quote;
  }

  get isClassified() {
    return this.product.product_type === ProductType.classified;
  }

  get collection() {
    return this.summary ? this.summary.collection : false;
  }

  get delivery() {
    if (this.product.delivery.isPresent && this.product.delivery.value.delivery_time_days) {
      return this.product.delivery.value.delivery_time_days;
    }
    return false;
  }

  get pending() {
    return this.product.status === ProductStatus.pending;
  }

  get draft() {
    return this.product.status === ProductStatus.draft;
  }

  get published() {
    return this.product.status === ProductStatus.publish;
  }

  get isEnabled() {
    return this.cart.canAdd(this.product).result || this.isClassified;
  }

  get stock() {
    return this.product.stock <= 0 ? 'Out of Stock' : 'In Stock';
  }

  get out_of_stock() {
    return this.product.stock <= 0;
  }
}
