import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { isBoolean } from 'util';
import { AuthService } from '../authorization/auth.service';
import { Link } from 'core';
import { ProductService } from '../products/product.service';
import { RouteHistoryService } from '../routehistory.service';
import { ProductFilterParams, SearchParamsKeys } from '../model/product';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  company: string;
  breadcrumbs: Link[];
  search: string;
  selectedCategory: string;

  private _categories: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private products: ProductService,
    private auth: AuthService,
    private routeHistory: RouteHistoryService
  ) {
    this.company = 'Agrichecker';
    this.search = '';

    this.breadcrumbs = [
      {
        title: '',
        url: '/'
      }
    ];
  }

  ngOnInit() {
    this.routeHistory.getPreviousRoute().subscribe((route: string) => {
      let routeParts = route.split('/');
      routeParts = routeParts.filter(entry => entry !== '');
      routeParts.pop();
      this.breadcrumbs = routeParts.map(routes => {
        const url = routes === 'orders' ? 'account/orders/' : routes;
        return {
          title: routes[0].toUpperCase() + routes.slice(1),
          url: '/' + url
        };
      });
    });
    this.products
      .getCategories()
      .pipe(map(cats => cats.map(c => c.name)))
      .subscribe(cats => (this._categories = cats));
    this.route.queryParamMap.subscribe((params: Params) => {
      this.search = params.get(SearchParamsKeys.search_string);
    });
  }

  get logo() {
    return 'assets/img/AC-ICON-300x300.png';
  }

  get username() {
    return this.auth.isLoggedIn ? this.auth.profile.name : '';
  }

  get categories(): string[] {
    return this._categories;
  }

  isLoggedIn() {
    return this.auth.isLoggedIn.pipe(map(signin => (typeof signin === 'boolean' ? signin : false)));
  }

  onKey(event: any) {
    if (event.key === 'Enter') {
      this.onClick();
    }
    this.search = event.target.value;
  }

  onClick() {
    const params: ProductFilterParams = {};
    if (this.search && this.search !== '') {
      params.search_string = this.search;
    }
    if (this.selectedCategory && this.selectedCategory !== this.categories[0] /*should be All Categories*/) {
      params.category = this.selectedCategory;
    }
    this.router.navigate(['/products'], { queryParams: params });
  }

  logout() {
    return this.auth.logout().subscribe(res => this.router.navigate(['/home']));
  }
}
