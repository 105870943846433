import { Component, OnInit } from '@angular/core';
import { ProductCategory } from 'core/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {}

  viewCategory(category: ProductCategory) {
    this.router.navigate(['/products'], {
      relativeTo: this.route,
      queryParams: {
        category: category.name
      }
    });
  }
}
