import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'core';
import { OrdersModule } from '../orders/orders.module';
import { CartButtonComponent } from './cart-button/cart-button.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentUnsuccessfulComponent } from './payment-unsuccessful/payment-unsuccessful.component';
import { QuoteProductsComponent } from './quote-products/quote-products.component';
import { ShopCoreModule } from '../core/shop-core.module';

@NgModule({
  declarations: [
    CheckoutComponent,
    CartComponent,
    CartButtonComponent,
    QuoteProductsComponent,
    PaymentUnsuccessfulComponent
  ],
  imports: [
    CommonModule,
    CheckoutRoutingModule,
    CoreModule,
    ShopCoreModule,
    NgbAlertModule,
    OrdersModule,
    ReactiveFormsModule
  ],
  exports: [CartButtonComponent]
})
export class CheckoutModule {}
