import { Component, forwardRef, Input, OnInit, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import {
  CityLookupService,
  DeliveryAddressProfile,
  ERROR,
  GeoPosition,
  Province,
  contain,
  BottomSheetComponent,
  distinct,
  GooglePlacesDirective,
  AddressFromGooglePlaceDetails,
  GeolocationService
} from 'core';
import { filter, switchMap, startWith, pairwise } from 'rxjs/operators';
import { billingAddressValidator, deliveryAddressValidator } from './address-validators';
import { MapsAPILoader } from '@agm/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

const DEFAULT_MAP_LOCATION = { longitude: 28.2293, latitude: -25.7479 };
const DEPRECATED_DEFAULT_MAP_LOCATION = { longitude: 25, latitude: -30 };
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})
export class AddressComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() for_delivery = false;
  @Input() formControl: FormControl;
  @Input() showForm = true;
  @ViewChild('places') placesDirective: GooglePlacesDirective;
  readonly errors = ERROR;

  show = true;
  private _municipalities: string[];
  private _selectedAddress: { formattedAddress: string; latLng: { latitude: number; longitude: number } };
  private _lastTouchedControl: string;
  private geocoderService: google.maps.Geocoder;
  private _geoLocation = { latitude: DEFAULT_MAP_LOCATION.latitude, longitude: DEFAULT_MAP_LOCATION.longitude };
  private _geocoderAddresses: google.maps.GeocoderResult[] = [];
  private _showPinLocationButton = false;

  @HostListener('blur') _onTouched: () => void;

  addressForm = this.fb.group({
    first_name: [''],
    last_name: [''],
    company: [''],
    vat_no: [''],
    address: this.fb.group({
      address_1: [''],
      address_2: [''],
      city: [''],
      postal_code: [''],
      province: [null],
      municipality: [null]
    }),
    email: [''],
    tel: [''],
    geo_location: this.fb.group({
      longitude: [null],
      latitude: [null]
    })
  });

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private mapsApiLoader: MapsAPILoader,
    private fb: FormBuilder,
    private cities: CityLookupService,
    private bottomSheet: MatBottomSheet,
    private geolocationService: GeolocationService
  ) {}

  ngAfterViewInit() {
    // When the addressForm is updated, and no geo_location is specified:
    // - use places directive to search for location, or
    // - use user location, if available, or
    // - use default location
    this.addressForm.valueChanges
      .pipe(
        filter(
          ({ geo_location: { latitude, longitude } }) =>
            this.for_delivery &&
            (latitude === null ||
              longitude === null ||
              (latitude === DEPRECATED_DEFAULT_MAP_LOCATION.latitude &&
                longitude === DEPRECATED_DEFAULT_MAP_LOCATION.longitude))
        )
      )
      .subscribe(value => {
        const { address_1, address_2, city } = value.address;
        const address = [address_1, address_2, city].filter(str => !!str && str !== '').join(' ');

        const canSearch = address.length > 3;
        const useMyLocationOrDefault = () => {
          return this.moveMarkerTo(this._geoLocation);
        };
        if (canSearch) {
          this.placesDirective.searchLocation(address).subscribe(results => {
            if (results.length > 0) {
              this.moveMarkerTo({
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng()
              });
            } else useMyLocationOrDefault();
          });
          return;
        }
        useMyLocationOrDefault();
      });
  }

  ngOnInit() {
    this.mapsApiLoader.load().then(() => {
      this.geocoderService = new google.maps.Geocoder();
    });

    this.getUserLocation();

    if (this.formControl) {
      const self = this;
      const { markAllAsTouched, reset } = this.formControl;
      this.formControl.markAllAsTouched = function() {
        markAllAsTouched.apply(this, arguments);
        self.addressForm.markAllAsTouched();
      };
      this.formControl.reset = function() {
        reset.apply(this, arguments);
        self.addressForm.reset();
      };
    }

    this.addressForm
      .get('address')
      .get('province')
      .valueChanges.pipe(
        filter(_ => this.for_delivery),
        startWith<any, any>(null),
        pairwise(),
        filter(([prev, prov]) => {
          const provinceExists = prov && contain(Object.values(Province), prov, (p1, p2) => p1 === p2);
          const provinceChanged = prov !== prev;
          const hasNoMunics = !this._municipalities || this._municipalities.length === 0;
          return provinceExists && (provinceChanged || hasNoMunics);
        }),
        switchMap(([_, prov]: string[]) => this.cities.getMunicipalitiesInProvince(prov)),
        filter((munics: string[]) => munics.length > 0)
      )
      .subscribe((munics: string[]) => {
        this._municipalities = munics;
      });

    this.addressForm.setValidators(this.for_delivery ? deliveryAddressValidator : billingAddressValidator);
  }

  writeValue(val: any): void {
    if (val) {
      this.addressForm.patchValue(val);
    }
  }

  registerOnChange(fn): void {
    this.addressForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.addressForm.disable();
    else this.addressForm.enable();
  }

  getUserLocation() {
    this.geolocationService.getPosition().subscribe({
      next: pos => (this._geoLocation = pos),
      error: er => console.warn('Failed to get geolocation with error:', er)
    });
  }

  get provinces() {
    return Object.values(Province);
  }

  get vat_no() {
    return !this.for_delivery;
  }

  get email() {
    return !this.for_delivery;
  }

  get phone() {
    return !!this.for_delivery;
  }

  get long() {
    return this.addressForm.get('geo_location').value.longitude || this._geoLocation.longitude;
  }

  get lat() {
    return this.addressForm.get('geo_location').value.latitude || this._geoLocation.latitude;
  }

  get munics(): string[] {
    return this._municipalities;
  }

  get municipalityPlaceholder() {
    return !this.munics || this.munics.length === 0 ? 'No Province Selected' : 'Select a Municipality';
  }

  public set geocoderAddresses(v: any[]) {
    this._geocoderAddresses = v;
    this._showPinLocationButton = v.length > 0;
  }

  get showPinLocationButton() {
    return this._showPinLocationButton;
  }

  get selectedAddress() {
    return this._selectedAddress;
  }

  /**Find the last field that is touched */
  private getLastTouched(formGroup: FormGroup) {
    if (!formGroup) return null;
    let res: string | null = null;
    Object.keys(formGroup.controls).forEach(controlName => {
      const control: any = formGroup.get(controlName);
      if (control.touched) {
        res = controlName;
        if (control.controls) {
          res += '.' + this.getLastTouched(control);
        }
      }
    });
    return res;
  }

  /**Loop through all form fields and make sure all skipped fields are touched */
  private markSkippedAsTouched(formGroup: FormGroup, lastTouchedControl?: string) {
    if (!lastTouchedControl) {
      lastTouchedControl = this.getLastTouched(formGroup);
      if (!lastTouchedControl || this._lastTouchedControl === lastTouchedControl) return;
      this._lastTouchedControl = lastTouchedControl;
    }

    for (const entry of Object.entries(formGroup.controls)) {
      const [controlName, control] = entry;

      if (controlName === lastTouchedControl.split('.').pop()) {
        return true;
      }
      (control as any).markAsTouched();
      if ((control as any).controls) return this.markSkippedAsTouched(control as any, lastTouchedControl);
    }
  }

  hasError(controlName: string, errorName: string = controlName.split('.').pop()) {
    const field = this.addressForm.get(controlName);
    if (!field) return false;

    this.markSkippedAsTouched(this.addressForm);

    return (
      this.addressForm.invalid &&
      (this.addressForm.dirty || this.addressForm.touched) &&
      this.addressForm.getError(errorName) &&
      field.touched
    );
  }

  onMapReady(map) {
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('addressSearch'));
  }

  onMarkerDragEnd($event) {
    if (this.for_delivery) {
      const address = ({
        geo_location: {
          latitude: $event.coords.lat,
          longitude: $event.coords.lng
        } as GeoPosition
      } as any) as DeliveryAddressProfile;

      this.reverseGeocodeMarkerLocation($event.coords.lat, $event.coords.lng);

      this.addressForm.patchValue(address);
    }
  }

  /**Update marker and initiate reverse geocoding on new location */
  private moveMarkerTo(latLng: { latitude; longitude }) {
    this.addressForm.patchValue({ geo_location: latLng });
    this.reverseGeocodeMarkerLocation(latLng.latitude, latLng.longitude);
  }

  /**Find place results for marker location and populate _geocoderAddresses */
  private reverseGeocodeMarkerLocation(lat, lng) {
    this.geocoderService.geocode({ location: { lat, lng } } as any, results => {
      this.geocoderAddresses = [
        ...results.filter(address =>
          address.types.find(type => ['street_address', 'establishment', 'premise'].includes(type))
        )
      ];

      if (this._geocoderAddresses.length === 0)
        // only if there is nothing else to show, show the routes.
        this.geocoderAddresses = [...results.filter(address => address.types.find(type => ['route'].includes(type)))];
    });

    // This is necessary in order to prevent delay in UI updates.
    setTimeout(() => {
      // Should preverably be in the callback above, but that results in the bottomsheet not responding
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  /** Present user with a list of reverse geocode results within a BottomSheet */
  openGeocodeAddressResults() {
    this.bottomSheet
      .open(BottomSheetComponent, {
        data: {
          list: distinct(
            this._geocoderAddresses.map(address => ({
              id: address.place_id,
              line_1: address.formatted_address
            })),
            'line_1'
          )
        }
      })
      .afterDismissed()
      .subscribe(r => {
        const selected = this._geocoderAddresses.find(a => a.place_id === r);
        if (!selected) return;
        const address = AddressFromGooglePlaceDetails(selected);
        const municipality =
          this.addressForm.get('address').get('city').value === address.city
            ? this.addressForm.get('address').get('municipality').value
            : null;
        this.addressForm.patchValue({ address: { ...AddressFromGooglePlaceDetails(selected), municipality } });
        this.addressForm.markAllAsTouched();
      });
  }

  onAddressChange($event: google.maps.places.PlaceResult) {
    const addressProfile: Partial<DeliveryAddressProfile> = {};
    addressProfile.address = AddressFromGooglePlaceDetails($event);

    // clear municipality incase city is changed
    addressProfile.address.municipality =
      this.addressForm.get('address').get('city').value === addressProfile.address.city
        ? this.addressForm.get('address').get('municipality').value
        : null;

    if (this.for_delivery && $event.geometry) {
      const geo_location = {
        latitude: $event.geometry.location.lat(),
        longitude: $event.geometry.location.lng()
      };

      addressProfile.geo_location = geo_location;
      this._selectedAddress = { formattedAddress: $event.formatted_address, latLng: geo_location };
    }
    this._showPinLocationButton = false; // Should not be visible if pin movement is a result of address change
    this.addressForm.patchValue(addressProfile);
    this.addressForm.markAllAsTouched();
    this.changeDetectorRef.detectChanges();
  }
}
