import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContactFormService, ContactFormInfo } from './contact-form.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from 'core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  model: ContactFormInfo;
  private sub: Subscription;
  alert: Alert;

  readonly AGRICHECKER_INFO = environment.agrichecker_info;

  constructor(private contact: ContactFormService) {
    this.model = {
      name: '',
      email: '',
      message: ''
    };
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  send() {
    this.sub = this.contact.send(this.model).subscribe(
      res => {
        this.alert = new Alert(AlertType.info, res.message);
        this.model = {} as ContactFormInfo;
      },
      err => {
        this.alert = new Alert(AlertType.danger, 'Message could not be sent');
      }
    );
  }
}
