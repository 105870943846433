import { Injectable } from '@angular/core';
import {
  CollectionLocationSummary,
  contain,
  DeliveryDetails,
  DeliveryFeeOption,
  DeliveryMethod,
  deliveryOverlap,
  DeliveryRate,
  flatMap,
  getDeliveryMethods,
  intersect,
  IProduct,
  OrderItem,
  OrderSummary,
  ProductType,
  Province,
  rateOverlap,
  UserProfile,
  VAT,
  VatRate,
  ERROR,
  NotificationService
} from 'core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, map, retry, tap } from 'rxjs/operators';
import { Cart, CartItem, CartProduct } from '../model/cart';
import { AppStorage } from '../model/storage';
import { ProductService } from '../products/product.service';
import { Router } from '@angular/router';

class CartStorage extends AppStorage<Cart> {
  private static readonly CART_KEY = 'cart';

  constructor() {
    super(CartStorage.CART_KEY);
  }

  protected serialize() {
    return JSON.stringify({
      items: this._data.items.values(),
      merchant: this._data.merchant
    });
  }

  protected deserialize(): Cart {
    const cart = new Cart();
    const data = JSON.parse(this.retrieve()) as Cart;

    if (data) {
      if (data.items instanceof Array) {
        data.items.forEach(d => {
          cart.add({
            product_id: +d.product_id,
            qty: +d.qty
          });
        });
      }
      cart.merchant = data.merchant;
    }
    return cart;
  }
}

interface Cache<T> {
  expire: boolean;
  value: T;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private _cart: CartStorage;
  private _products: Array<CartProduct>;
  private _delivery_methods_cache: Cache<Set<DeliveryMethod>>;

  constructor(
    private product: ProductService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this._cart = new CartStorage();
    this._products = new Array();
    this._delivery_methods_cache = {
      expire: true,
      value: null
    };
  }

  get cart(): Cart {
    return this._cart.get();
  }

  add(product: IProduct, qty: number = product.batch_size || product.order_size.min || 1) {
    let cart: Cart;
    const item: CartItem = {
      product_id: +product.id,
      qty: +qty
    };

    if (this.canAdd(product, +qty).result) {
      this._delivery_methods_cache.expire = true;
      if (this._cart.exists()) {
        cart = this._cart.get();
      } else {
        cart = new Cart();
      }

      cart.add(item, product.merchant, getDeliveryMethods(product));
      this._cart.put(cart);

      const prod = {
        product: product,
        qty: +qty
      };
      const ix = this._products.findIndex(p => p.product.id === prod.product.id);
      if (ix > -1) {
        this._products[ix].qty += +qty;
      } else {
        this._products.push(prod);
      }

      this.notificationService.openSnackbar(
        {
          data: {
            message: `${prod.qty} x ${product.name} added to cart.`,
            type: 'success',
            action: 'View Cart',
            icon: 'add_shopping_cart'
          }
        },
        () => {
          this.router.navigate(['cart']);
        }
      );
    }
  }

  clear() {
    this._products.length = 0;
    this.cart.removeAll();
    this._cart.remove();

    this._delivery_methods_cache.expire = true;
  }

  remove(id: number) {
    const cart = this._cart.get();
    cart.remove(id);
    this._cart.put(cart);
    this._delivery_methods_cache.expire = true;
    this._products.splice(
      this._products.findIndex(prod => prod.product.id === id),
      1
    );
  }

  updateItem(id: number, qty: number) {
    const cart = this._cart.get();
    cart.update(id, +qty);
    this._cart.put(cart);
    this._delivery_methods_cache.expire = true;
    const ix = this._products.findIndex(prod => prod.product.id === id);
    this._products[ix].qty = +qty;
  }

  updateMerchant(merchant: UserProfile) {
    const cart = this._cart.get();
    cart.updateMerchant(merchant);
    this._cart.put(cart);
  }

  get items(): Array<CartItem> {
    return this._cart.get().items.values();
  }

  getCartProducts(): Observable<Array<CartProduct>> {
    const ids = this.cart.items.values().map(i => i.product_id);
    if (ids && ids.length === 0) {
      return EMPTY;
    }
    return this.product.getProductsByIds(ids).pipe(
      map(prods => {
        if (prods && prods.length === 0) {
          throw new Error('Empty!');
        } else {
          return prods;
        }
      }),
      retry(5),
      filter((val: IProduct[] | void) => Array.isArray(val)),
      map((prods: IProduct[]) => {
        return prods.map(prod => {
          const cart_prod = this._cart.get().items.item(prod.id.toString());
          return {
            qty: cart_prod ? cart_prod.qty : 1,
            product: prod
          } as CartProduct;
        });
      }),
      tap(prods => {
        this._products = prods;
        this._delivery_methods_cache.expire = true;
      })
    );
  }

  private get products(): Array<CartProduct> {
    return this._products;
  }

  get shop_products(): Array<CartProduct> {
    return this.products.filter(prod => prod.product.product_type === ProductType.shop);
  }

  get quote_products(): Array<CartProduct> {
    return this.products.filter(prod => prod.product.product_type === ProductType.quote);
  }

  get collection_locations(): CollectionLocationSummary[] {
    if (this.products.length > 0) {
      const locations = this.products
        .filter(prod => prod.product.collection_locations.isPresent)
        .map(prod => prod.product.collection_locations.value)
        .filter(locs => locs.length > 0)
        .reduce((prev, current) => Array.from(intersect(new Set([...prev]), new Set([...current]))));
      return locations;
    }
    return [];
  }

  get delivery_options(): DeliveryMethod[] {
    return Array.from(this.getCartDeliveryMethods());
  }

  get delivery_locations(): DeliveryRate[] {
    return flatMap(
      this.products
        .map(prod => prod.product)
        .filter(prod => prod.delivery.isPresent)
        .map(prod => prod.delivery.value)
        .map(del => {
          return del.rates.map(rate => rate as DeliveryRate);
        })
    );
  }

  get count() {
    return this._cart.exists() ? this._cart.get().count : 0;
  }

  get merchant(): UserProfile {
    return this.cart.merchant;
  }

  createOrder(delivery?: number): OrderSummary {
    const order_items = this.shop_products.map(prod => {
      const vat =
        prod.product.price.value && prod.product.price.value.vat_rate === VatRate.Standard
          ? prod.product.price.value.price_excl_vat * VAT.STANDARD
          : VAT.ZERO;
      return {
        id: prod.product.id,
        qty: prod.qty,
        unit: prod.product.unit_size + ' ' + prod.product.unit,
        name: prod.product.name,
        price: prod.product.price.value.price_excl_vat,
        vat: vat
      } as OrderItem;
    });

    if (order_items.length > 0 && delivery) {
      const delivery_vat = delivery * VAT.STANDARD;
      const delivery_total = delivery + delivery_vat;
      return new OrderSummary(order_items, delivery_total, delivery_vat);
    } else {
      return new OrderSummary(order_items);
    }
  }

  canAdd(
    product: IProduct,
    qty: number = product.batch_size || product.order_size.min || 1
  ): { result: boolean; message?: string } {
    const errors = {
      outOfStock: { result: false, message: ERROR.OUT_OF_STOCK },
      differentMerchant: { result: false, message: ERROR.DIFFERENT_MERCHANT },
      differentDeliveryMethod: { result: false, message: ERROR.DIFFERENT_DELIVERY_METHOD },
      differentRatesOrCollection: { result: false, message: ERROR.DIFFERENT_RATES_AND_COLLECTION_LOCATION },
      itemClassified: { result: false }
    };

    // should only add shop or quote products
    if (product.product_type === ProductType.classified) return errors.itemClassified;
    // product should be in stock
    if (!this.isInStock(product, qty) && product.product_type === ProductType.shop) {
      return errors.outOfStock;
    }
    // can add if it is the first product in the cart
    if (this.count === 0) return { result: true };

    // if it is the same merchant
    if (!this.cart.isSameMerchant(product)) return errors.differentMerchant;

    if (!this.deliveryMethodOverlap(product)) return errors.differentDeliveryMethod;

    return this.deliveryRatesOverlap(product) || this.collectionLocationOverlap(product)
      ? { result: true }
      : errors.differentRatesOrCollection;
  }

  private deliveryMethodOverlap(product: IProduct) {
    const delivery_methods = this.getCartDeliveryMethods();
    if (delivery_methods.size === 0) return true;
    const sect = intersect(delivery_methods, getDeliveryMethods(product));
    return sect.size > 0;
  }

  private deliveryRatesOverlap(product: IProduct) {
    if (
      contain(this.delivery_options, DeliveryMethod.Delivery, (del1, del2) => del1 === del2) &&
      product.delivery.isPresent
    ) {
      const rates = product.delivery.getOrElse({ rates: [] } as DeliveryDetails).rates;
      return rates.filter(r => deliveryOverlap(r, new Set([...this.delivery_locations]))).length > 0;
    }
    return false;
  }

  private collectionLocationOverlap(product: IProduct): boolean {
    if (
      contain(this.delivery_options, DeliveryMethod.Collection, (del1, del2) => del1 === del2) &&
      product.collection_locations.isPresent
    ) {
      const locations = product.collection_locations.getOrElse([]);
      return (
        locations.filter(loc => contain(this.collection_locations, loc, (loc1, loc2) => loc1.id === loc2.id)).length > 0
      );
    }
    return false;
  }

  private getCartDeliveryMethods(): Set<DeliveryMethod> {
    if (this._delivery_methods_cache.expire) {
      const delivery_methods = intersect(this.products.map(prod => getDeliveryMethods(prod.product)));
      this._delivery_methods_cache = {
        value: delivery_methods,
        expire: false
      };
      return delivery_methods;
    } else {
      return this._delivery_methods_cache.value;
    }
  }

  private isInStock(product: IProduct, qty: number) {
    if (product.product_type === ProductType.quote) return true;

    const prod = {
      product: product,
      qty: +qty
    };
    const ix = this._products.findIndex(p => p.product.id === prod.product.id);
    if (ix > -1) {
      const newqty = this._products[ix].qty + +qty;
      if (newqty > product.stock || newqty > product.order_size.max) {
        return false;
      }
    }
    if (!!product.batch_size) {
      const exceed_batch = !!product.batch_size && product.stock >= product.batch_size;
      return product.stock > 0 && exceed_batch;
    }
    return product.stock > 0;
  }

  calculateDeliveryFee(province: Province, municipality: string): number {
    // for each product in cart get delivery fee at specific location
    // check if it should be calculated per order or per batch
    if (province && municipality) {
      return this.products
        .filter(prod => prod.product.delivery.isPresent)
        .map(prod => {
          return {
            qty: prod.qty,
            batch_size: prod.product.batch_size,
            delivery: prod.product.delivery.value
          };
        })
        .map(details => {
          const ix = details.delivery.rates.findIndex(r =>
            rateOverlap(r, { province: province, municipality: municipality })
          );

          return {
            batch: details.delivery.order_or_batch === DeliveryFeeOption.batch,
            batch_size: details.batch_size,
            qty: details.qty,
            rate: ix > -1 ? details.delivery.rates[ix].rate : false
          };
        })
        .filter(rate => typeof rate.rate !== 'boolean')
        .map(current => (current.batch ? +current.rate * (current.qty / current.batch_size) : +current.rate))
        .reduce((prev, curr) => (prev += curr), 0);
    }
    return 0;
  }

  canDeliverTo(province: Province, municipality: string): boolean {
    // for each product - check if we can deliver to province && municipality
    return (
      this.products
        .filter(prod => prod.product.delivery.isPresent)
        .map(prod => prod.product.delivery.value)
        .filter(del =>
          deliveryOverlap(
            {
              province: province,
              municipality: municipality
            } as DeliveryRate,
            new Set([...del.rates])
          )
        ).length === this.products.length
    );
  }
}
