import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Endpoints } from 'core';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class LoadSpinnerInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith(Endpoints.product_options)) this.spinner.show();

    return next.handle(req).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      )
    );
  }
}
