import { Component, OnInit, Input } from '@angular/core';
import { UserProfile } from 'core';

@Component({
  selector: 'app-quote-products',
  templateUrl: './quote-products.component.html',
  styleUrls: ['./quote-products.component.scss']
})
export class QuoteProductsComponent implements OnInit {
  @Input() merchant: UserProfile;
  @Input() products;

  constructor() {}

  ngOnInit() {}
}
