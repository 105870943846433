<div class="container mb-1">
  <div class="row">
    <div class="col">
      <a routerLink="/account"
        ><label class="ac-nav-link" [ngClass]="{ 'ac-nav-link-unselected': !(active === 'profile') }">Profile</label></a
      ><label> |</label
      ><a routerLink="/account/orders"
        ><label class="ac-nav-link pl-1" [ngClass]="{ 'ac-nav-link-unselected': !(active === 'orders') }"
          >Orders</label
        ></a
      >
    </div>
  </div>
</div>
<router-outlet></router-outlet>
