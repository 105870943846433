<div class="container">
  <div class="row align-items-center">
    <div class="col">
      <h4><b>SHOP</b> NOW:</h4>
      <app-categories (selectionChange)="viewCategory($event)"></app-categories>
      <app-ad [position]="'leaderboard'"></app-ad>
      <app-slideshow></app-slideshow>
      <div class="row mt-5">
        <div class="col">
          <h4 class="mb-3">HOW DOES <strong>AGRICHECKER</strong> WORK?</h4>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-8 mb-5">
          <iframe
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/WthZklTj458?feature=oembed"
            frameborder=""
            allow="autoplay; encrypted-media"
            allowfullscreen=""
          ></iframe>
        </div>
        <div class="col-sm-4">
          <app-ad [position]="'sideblock'"></app-ad>
        </div>
      </div>
    </div>
  </div>
</div>
