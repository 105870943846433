export class AppStorage<T> {
  private key: string;
  protected _data: T;

  constructor(key: string) {
    this.key = key;
    this._data = this.deserialize();
  }

  exists(): boolean {
    return !!this.retrieve();
  }

  retrieve(): string {
    return localStorage.getItem(this.key);
  }

  put(data: T) {
    this._data = data;
    localStorage.setItem(this.key, this.serialize());
  }

  remove() {
    localStorage.removeItem(this.key);
    this._data = this.deserialize();
  }

  get(): T {
    return this._data;
  }

  protected serialize(): string {
    return JSON.stringify(this._data);
  }

  protected deserialize(): T {
    return JSON.parse(this.retrieve());
  }
}
