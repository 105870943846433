import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProductService } from '../product.service';
import { ProductCategory } from 'core/core';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<ProductCategory>();
  @Input() selectedCategory: string;

  private _categories: ProductCategory[];

  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    this.productService.getCategories().subscribe(categories => {
      this._categories = categories;
    });
  }

  get categories(): string[] {
    return this._categories ? this._categories.map(c => c.name).slice(1) : [];
  }

  select(category: string) {
    if (this.selectedCategory === category) {
      this.selectedCategory = null;
    } else {
      this.selectedCategory = category;
    }

    const cat = this._categories.find(c => c.name === this.selectedCategory);
    this.selectionChange.emit(cat);
  }
}
