import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetails } from 'core';
import { UserService } from '../../user.service';
import { MerchantInfoComponent } from '../merchant-info/merchant-info.component';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  @Input() order: OrderDetails;

  constructor(private modalService: NgbModal, private user: UserService) {}

  ngOnInit() {}

  open() {
    this.user.getMerchantById(this.order.merchant.id).subscribe(merchant => {
      const modalRef = this.modalService.open(MerchantInfoComponent);
      modalRef.componentInstance.merchant = merchant;
    });
  }
}
