import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  Alert,
  AlertType,
  CollectionLocation,
  CollectionLocationSummary,
  DeliveryRate,
  IProduct,
  MerchantProfile,
  ProductType,
  ProductUtil,
  ImageSize,
  NotificationService
} from 'core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../authorization/auth.service';
import { CartService } from '../../checkout/cart.service';
import { LocationService } from '../../core/location.service';
import { UserService } from '../../user.service';
import { ProductService } from '../product.service';

interface Button {
  icon: string;
  label: string;
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  private productID: number;
  private _product: IProduct;
  private _merchant: MerchantProfile;
  private _category: string;
  alert: Alert;
  longitude: number;
  latitude: number;

  isUrl = ProductUtil.isUrl;
  isFile = ProductUtil.isFile;
  isOther = ProductUtil.isOther;
  isBoolean = ProductUtil.isBoolean;

  constructor(
    private productService: ProductService,
    private location: LocationService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private users: UserService,
    private cart: CartService,
    private notificationService: NotificationService
  ) {}

  buttons = {
    shop: {
      icon: 'fa-shopping-cart',
      label: 'BUY'
    },
    quote: {
      icon: 'fa-edit',
      label: 'REQUEST QUOTE'
    }
  };

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((params: Params) => {
          this.productID = params.get('id');
          return this.productID;
        }),
        switchMap(id => {
          return this.productService.getProductById(this.productID);
        }),
        tap(product => {
          this._product = product;
          if (product.collection_locations.value && product.collection_locations.value[0]) {
            this.loadLocation(product.collection_locations.value[0].id);
          }
        }),
        obs$ => combineLatest([obs$, this.productService.getCategories()]),
        switchMap(([product, categories]) => {
          const cat = categories.find(c => +c.id === +product.category_id);
          this._category = cat ? cat.name : '';
          return this.auth.isLoggedIn.value ? this.users.getMerchantById(product.merchant.id) : of(undefined);
        }),
        filter(res => res)
      )
      .subscribe(res => {
        this._merchant = res;
      });
  }

  private loadLocation(id: string): void {
    this.loggedIn
      .pipe(
        filter(loggedIn => loggedIn),
        switchMap(_ => this.location.getCollectionLocationById(id))
      )
      .subscribe((location: CollectionLocation) => {
        this.longitude = +location.geo_location.longitude;
        this.latitude = +location.geo_location.latitude;
      });
  }

  addToCart() {
    this.cart.add(this._product);
  }

  disabledInfo() {
    this.notificationService.openSnackbar({
      data: {
        type: 'info',
        message: this.cart.canAdd(this._product).message
      }
    });
  }

  get canAdd(): boolean {
    const qty = this._product.batch_size ? this._product.batch_size : this._product.order_size.min;
    return this.cart.canAdd(this._product, qty).result;
  }

  get product_image() {
    return this._product && this._product.id
      ? this.productService.getProductImage(this._product.id, ImageSize.large)
      : '';
  }

  get name() {
    return this._product ? this._product.name : '';
  }

  get price() {
    if (!this.isQuote && this._product.price.value && this._product.price.value.price_excl_vat) {
      return this._product.price.value.price_excl_vat;
    }
    return 'Request for Price';
  }

  get isQuote() {
    return this._product.product_type === ProductType.quote;
  }

  get unitSize() {
    return this._product ? this._product.unit_size : NaN;
  }

  get unit() {
    return this._product ? this._product.unit : '';
  }

  get minQuantity() {
    return this._product ? this._product.order_size.min : NaN;
  }

  get maxQuantity() {
    return this._product ? this._product.order_size.max : NaN;
  }

  get button(): Button {
    if (this._product && this._product.product_type === ProductType.shop) {
      return this.buttons.shop;
    }
    if (this._product && this._product.product_type === ProductType.quote) {
      return this.buttons.quote;
    }
    return;
  }

  get loggedIn(): Observable<boolean> {
    return this.auth.isLoggedIn.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.auth.redirectUrl = '/products/' + this.productID;
        }
      })
    );
  }

  get merchant(): MerchantProfile {
    return this._merchant ? this._merchant : ({} as MerchantProfile);
  }

  get productExists(): boolean {
    return !!this._product;
  }

  get batchSize(): string {
    return this._product ? String(this._product.batch_size) : '';
  }

  get category(): string {
    return this._product && this._category ? this._category : '';
  }

  get description(): string {
    return this._product ? this._product.description : '';
  }

  get fields() {
    return this._product ? this._product.fields : [];
  }

  get collection_locations(): CollectionLocationSummary[] {
    return this._product.collection_locations.value && this._product.collection_locations.value.length > 0
      ? this._product.collection_locations.value
      : <CollectionLocationSummary[]>(<unknown>false);
  }

  get delivery_rates(): DeliveryRate[] {
    return this._product.delivery.isPresent ? this._product.delivery.value.rates : [];
  }

  get for_delivery(): Boolean {
    return this._product.delivery.isPresent;
  }

  get is_shop() {
    return this._product.product_type === ProductType.shop;
  }

  get stock() {
    return this._product.stock <= 0 ? 'Out of Stock' : 'In Stock';
  }

  get out_of_stock() {
    return this._product.stock <= 0;
  }
}
