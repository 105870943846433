import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CollectionLocation, CollectionLocationSummary } from 'core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocationService } from '../location.service';

@Component({
  selector: 'app-collection-locations',
  templateUrl: './collection-locations.component.html',
  styleUrls: ['./collection-locations.component.scss']
})
export class CollectionLocationsComponent implements OnInit {
  private _locations: CollectionLocationSummary[];
  selectedLocationControl = new FormControl('');
  private _selected_location: CollectionLocation;
  error_msg: string;

  @Output() locationChanged = new EventEmitter<CollectionLocation>();

  constructor(private loc: LocationService) {}

  ngOnInit() {
    this.selectedLocationControl.valueChanges
      .pipe(
        map(ix => {
          return this._locations[ix].id;
        }),
        switchMap(id =>
          this.loc
            .getCollectionLocationById(id)
            .pipe(catchError(_ => of('The selected Collection Location does not exist anymore')))
        )
      )
      .subscribe(loc => {
        if (typeof loc === 'string') {
          this.error_msg = <string>loc;
        } else {
          this._selected_location = <CollectionLocation>loc;
          this.locationChanged.emit(this._selected_location);
        }
      });
  }

  @Input()
  set locations(val: CollectionLocationSummary[]) {
    if (val) {
      this._locations = val;
    }
  }

  get locations() {
    return this._locations;
  }

  get selected_location(): CollectionLocation {
    return this._selected_location;
  }

  @Input()
  set location(val: CollectionLocation) {
    if (val) this._selected_location = val;
  }
}
