import { Component, Input, OnInit } from '@angular/core';
import { AdLink, AdService, AdPosition } from '../../ad.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit {
  @Input() position;

  private _ad_lb: AdLink;
  private _ad_sb: AdLink;
  constructor(private adService: AdService, private router: Router) {}

  ngOnInit() {
    this.adService.leaderboard.subscribe(ad => {
      this._ad_lb = ad;
    });

    this.adService.sideblock.subscribe(ad => {
      this._ad_sb = ad;
    });
  }

  goToUrl() {
    this.router.navigateByUrl(this.ad.url.url);
  }

  get ad(): AdLink {
    if (this.position === AdPosition.leaderboard) {
      return this._ad_lb;
    } else if (this.position === AdPosition.sideblock) {
      return this._ad_sb;
    }
  }
}
