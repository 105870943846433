import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account/account.component';
import { AccountInfoComponent } from './accountinfo/accountinfo.component';

@NgModule({
  declarations: [AccountInfoComponent, AccountComponent],
  imports: [CommonModule, ReactiveFormsModule, AccountRoutingModule]
})
export class AccountModule {}
