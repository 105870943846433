import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  CollectionLocation,
  CollectionLocationUtil,
  BillingProfile,
  CustomerProfile,
  DeliveryAddressProfile,
  DeliveryMethod,
  ERROR,
  OrderRequest,
  OrderResponse,
  PaymentMethods,
  Province,
  UserProfile,
  DeliveryInfo,
  Address,
  distinct
} from 'core';
import { forkJoin, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { billingAddressValidator, deliveryAddressValidator } from '../../core/address/address-validators';
import { CartItem } from '../../model/cart';
import { QuoteRequest, QuoteResponse } from '../../model/quote';
import { OrderService } from '../../orders/order.service';
import { TermsComponent } from '../../registration/terms/terms.component';
import { UserService } from '../../user.service';
import { CartService } from '../cart.service';
import { PaymentService } from '../payment.service';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  delivery_method = new FormControl(DeliveryMethod.Delivery);
  ac_terms = new FormControl(false);
  merchant_terms = new FormControl(false);
  readonly errors = ERROR;

  delivery_address = new FormControl('', [deliveryAddressValidator]);
  billing_address = new FormControl('', [billingAddressValidator]);

  private _deliveryHistory: DeliveryInfo[];
  private _showDeliveryForm = false;
  private _selectedAddressIndex: number | null = null;

  private _merchant_tsandcs: string;
  private _collection_location: CollectionLocation;

  private _delivery_location = {
    province: Province.Gauteng,
    municipality: ''
  };

  private subs: Array<Subscription> = new Array();
  private modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private cart: CartService,
    private user: UserService,
    private orderService: OrderService,
    private router: Router,
    private quote: QuoteService,
    private payment: PaymentService
  ) {}

  ngOnInit() {
    this.orderService
      .getOrders()
      .pipe(first())
      .subscribe({
        next: orders => {
          const deliveryInfo = orders
            .filter(order => order.delivery_method === 'delivery')
            .map(order => order.delivery_info);

          this._deliveryHistory = distinct(
            deliveryInfo,
            (a, b) => a.address && b.address && Address.compare(a.address, b.address)
          ).slice(0, 5);
          if (this._deliveryHistory.length > 0) {
            this.selectDeliveryAddress(0);
          } else this._showDeliveryForm = true;
        },
        error: _e => {
          this._deliveryHistory = [];
          this._selectedAddressIndex = null;
          this._showDeliveryForm = true;
        }
      });

    this.subs.push(
      this.delivery_address.valueChanges.subscribe((address: DeliveryAddressProfile) => {
        const selected = this.addressList[this._selectedAddressIndex];
        if (!this._showDeliveryForm && selected && address && !Address.compare(selected.address, address.address)) {
          this._selectedAddressIndex = null;
          this._showDeliveryForm = true;
        }
      })
    );

    this.subs.push(
      this.delivery_address.valueChanges
        .pipe(
          filter((addr: DeliveryAddressProfile) => {
            return this._delivery_location.municipality !== addr.address.municipality;
          })
        )
        .subscribe((addr: DeliveryAddressProfile) => {
          this._delivery_location = {
            municipality: addr.address.municipality,
            province: addr.address.province
          };
        })
    );

    this.subs.push(
      this.user.getFullProfile().subscribe((profile: CustomerProfile) => this.billing_address.patchValue(profile))
    );

    if (this.merchant.id) {
      this.subs.push(
        this.user.getMerchantById(this.merchant.id).subscribe(profile => {
          this.cart.updateMerchant(profile);
          this._merchant_tsandcs = profile.terms_and_conditions;
        })
      );
    }

    if (this.cart && this.cart.delivery_options && this.cart.delivery_options.length > 0) {
      this.delivery_method.setValue(this.cart.delivery_options[0]);
    } else if (!this.cart.delivery_options || this.cart.delivery_options.length === 0) {
      this.subs.push(this.cart.getCartProducts().subscribe());
    }
  }

  ngOnDestroy() {
    this.subs.filter(sub => sub).forEach(sub => sub.unsubscribe());
  }

  get order() {
    return this.cart.createOrder(
      this.cart.calculateDeliveryFee(this._delivery_location.province, this._delivery_location.municipality)
    );
  }

  get deliveryHistory() {
    return this._deliveryHistory;
  }

  get quote_products() {
    return this.cart.quote_products;
  }

  get can_deliver() {
    return this.cart && this.cart.delivery_options.findIndex(d => d === DeliveryMethod.Delivery) > -1;
  }

  get can_collect() {
    return this.cart && this.cart.delivery_options.findIndex(d => d === DeliveryMethod.Collection) > -1;
  }

  get mutliple_delivery_options(): boolean {
    return this.can_collect && this.can_deliver;
  }

  get collection_locations() {
    return this.cart.collection_locations;
  }

  get will_deliver(): boolean {
    if (!this.delivered) return true;

    if (!(this._delivery_location.province && this._delivery_location.municipality)) return false;
    return this.cart.canDeliverTo(this._delivery_location.province, this._delivery_location.municipality);
  }

  get has_shop_products() {
    return this.cart.shop_products.length > 0;
  }

  get delivered() {
    if (this.can_deliver) {
      return this.delivery_method.value === DeliveryMethod.Delivery;
    }
    return false;
  }

  get selectedAddress() {
    return this._selectedAddressIndex && this.addressList[this._selectedAddressIndex];
  }

  get selectedAddressIndex() {
    return this._selectedAddressIndex;
  }

  get hasDeliveryHistory() {
    return this._deliveryHistory && this._deliveryHistory.length > 0;
  }

  get addressList() {
    return this._deliveryHistory;
  }

  get showDeliveryForm() {
    return this._showDeliveryForm;
  }

  editDeliveryAddress(i: number) {
    this._showDeliveryForm = true;
    setTimeout(() => {
      this.selectDeliveryAddress(i);
    }, 0);
  }

  selectDeliveryAddress(i: number | null) {
    if (i && this._selectedAddressIndex === i) return;

    this._selectedAddressIndex = i;
    if (i == null) {
      this.delivery_address.reset({
        address: {}
      });
      return;
    }
    this.delivery_address.patchValue(this.deliveryHistory[i]);
  }

  usePreviousAddress() {
    this._showDeliveryForm = false;
    if (!this._selectedAddressIndex) this.selectDeliveryAddress(0);
  }

  addNewDeliveryAddress() {
    this.selectDeliveryAddress(null);
    this._showDeliveryForm = true;
  }

  formatAddress(add: Address) {
    const { address_1, address_2, city, postal_code, province } = add;
    return [address_1, address_2, city, province, postal_code].filter(str => !!str && str !== '').join(', ');
  }

  openTerms() {
    const modalRef = this.modalService.open(TermsComponent);
    modalRef.result.then(
      res => {
        this.ac_terms.setValue(!!res);
      },
      _ => this.ac_terms.setValue(false)
    );
  }

  openMerchantTerms(content: any) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then(
      res => {
        this.merchant_terms.setValue(!!res);
      },
      err => this.merchant_terms.setValue(false)
    );
  }

  accept() {
    this.modalRef.close(true);
  }

  decline() {
    this.modalRef.close(false);
  }

  get merchant(): UserProfile {
    return this.cart.merchant;
  }

  get tsandcs() {
    return this._merchant_tsandcs;
  }

  update_collection_location(location: CollectionLocation) {
    this._collection_location = location;
  }

  get selected_location() {
    return this._collection_location;
  }

  /**
   * Create a new order
   *
   * Save billing and delivery address information to user profile
   * vat number and vat certificate
   */
  placeOrder() {
    const requests = {};
    if (this.order.items && this.order.items.length > 0) {
      requests['order'] = this.placeNewOrder();
    }
    if (this.quote_products && this.quote_products.length > 0) {
      requests['quote'] = this.requestQuote();
    }

    forkJoin(Object.values(requests)).subscribe((res: any[]) => {
      if (res.length === 2) {
        const order_resp: OrderResponse = res[0];

        this.payment.pay(order_resp.payment_info);
        // pay for order & remember quote request also sent
      } else if (requests.hasOwnProperty('order')) {
        const order_resp: OrderResponse = res[0];

        // pay for order
        this.payment.pay(order_resp.payment_info);
      } else if (requests.hasOwnProperty('quote')) {
        const quote_resp: QuoteResponse = res[0];

        this.router.navigate([
          '/order-completed',
          {
            quote: quote_resp.id
          }
        ]);
      }
    });
  }

  back() {
    this.router.navigate(['/cart']);
  }

  private placeNewOrder() {
    const request: OrderRequest = {
      order: this.order,
      billing_address: this.billing_address.value,
      merchant: this.merchant,
      payment_method: PaymentMethods.Ozow
    };
    if (this.delivered) {
      request.delivery_address = this.delivery_address.value;
    } else {
      request.collection_location = this._collection_location
        ? CollectionLocationUtil.toSummary(this._collection_location)
        : null;
    }
    return this.orderService.placeOrder(request);
  }

  useBillingDetails() {
    const { first_name, last_name, address, company } = this.billing_address.value as BillingProfile;
    this._showDeliveryForm = true;
    setTimeout(() => {
      this.delivery_address.setValue({
        first_name,
        last_name,
        address: { ...address, municipality: null },
        company,
        geo_location: { latitude: null, longitude: null }
      } as DeliveryAddressProfile);
      this.delivery_address.markAllAsTouched();
    }, 0);
  }

  /**
   * Send a request for quote
   */
  private requestQuote() {
    const quote: QuoteRequest = {
      merchant: this.merchant,
      customer: this.user.profile,
      delivery_method: this.delivery_method.value,
      items: this.quote_products.map(prod => {
        return {
          product_id: prod.product.id,
          qty: prod.qty
        } as CartItem;
      })
    };

    if (this.delivery_method.value === DeliveryMethod.Delivery) {
      quote.delivery_info = this.delivery_address.value;
    } else {
      quote.collection_location = this._collection_location
        ? CollectionLocationUtil.toSummary(this._collection_location)
        : null;
    }

    return this.quote.request(quote);
  }

  get valid() {
    let validcontrols = true;
    if (this.delivery_method.value === DeliveryMethod.Collection) {
      validcontrols = !!this._collection_location && this.billing_address.valid;
    } else if (this.delivery_method.value === DeliveryMethod.Delivery) {
      validcontrols = this.delivery_address.valid && this.billing_address.valid;
    }
    return this.ac_terms.value && this.merchant_terms.value && validcontrols;
  }

  get invalid() {
    return !(this.valid && this.will_deliver);
  }
}
