import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-cart-btn',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss']
})
export class CartButtonComponent implements OnInit {
  constructor(private router: Router, private cart: CartService) {}

  ngOnInit() {}

  open() {
    this.router.navigate(['/cart']);
  }

  get count() {
    return this.cart.count;
  }
}
