import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  constructor(private activeModal: NgbActiveModal) {}

  accept() {
    this.activeModal.close(true);
  }

  decline() {
    this.activeModal.close(false);
  }
}
