import { Injectable } from '@angular/core';
import { ApiService, AuthProfile, Endpoints, LoginResult, Namespace, Roles } from 'core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStorage } from '../model/storage';

export interface ApiResponse {
  status: number;
  error: any;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private session = new AppStorage<AuthProfile>('ACjwt');

  private loggedIn$: BehaviorSubject<boolean>;
  redirectUrl: string;

  constructor(private api: ApiService) {
    this.loggedIn$ = new BehaviorSubject(this.session.exists());
  }

  private storeData(data: AuthProfile) {
    if (this.isAuthSuccess(data)) {
      this.session.put(data);
    }
  }

  private isAuthSuccess(data: AuthProfile | ApiResponse): data is AuthProfile {
    return (<AuthProfile>data).token !== undefined;
  }

  private getAuthToken(request: any) {
    return <Observable<AuthProfile | ApiResponse>>this.api.post(Namespace.jwt, Endpoints.signin, request);
  }

  forgotPassword(email: string) {
    const params = '?email=' + email;
    return this.api.get(Namespace.ac, Endpoints.forgot_password + params);
  }

  resetPassword(password: string, email: string) {
    const body = {
      new_password: password,
      email: email
    };
    return this.api.post(Namespace.ac, Endpoints.update_password, body);
  }

  validateAuthToken() {
    let decode;
    try {
      decode = jwt_decode(this.authToken);
    } catch {
      decode = { exp: 0 };
    }
    if (decode.exp * 1000 < Date.now() && this.loggedIn$.value) {
      this.logout();
    }
  }

  login(username: string, password: string): Observable<LoginResult> {
    const obs = this.getAuthToken({
      username: username,
      password: password
    }).pipe(
      map(data => {
        if (
          this.isAuthSuccess(data) &&
          (data.roles.includes(Roles.merchant) || data.roles.includes(Roles.pending_merchant))
        ) {
          return {
            authorised: false,
            is_merchant: true
          } as LoginResult;
        } else if (this.isAuthSuccess(data) && data.roles.includes(Roles.customer)) {
          this.storeData(<AuthProfile>data);
          return {
            authorised: true
          };
        }
        return {
          authorised: false
        };
      })
    );
    obs.subscribe(res => {
      this.loggedIn$.next(res.authorised);
    });

    return obs;
  }

  logout(): Observable<boolean> {
    this.session.remove();
    this.loggedIn$.next(false);
    return of(true);
  }

  get isLoggedIn(): BehaviorSubject<boolean> {
    this.validateAuthToken();
    return this.loggedIn$;
  }

  get profile(): AuthProfile {
    return this.session.get();
  }

  get authToken(): string {
    return this.session.get() ? this.session.get().token : '';
  }
}
