<div class="card mb-2 d-flex">
  <div class="row mr-1 p-2">
    <div class="col-lg-2 col-md-3 col-0 d-flex align-items-center justify-content-center">
      <div *ngIf="product_image; else default">
        <img class="img-thumbnail d-none d-md-block" [routerLink]="product.id" [src]="product_image" alt="Logo" />
      </div>
      <ng-template #default
        ><a [routerLink]="product.id"><i class="fas fa-box-open fa-2x ac-product-logo block d-none d-md-block"></i></a
      ></ng-template>
    </div>
    <div class="col-lg-7 col-md-6 col-7">
      <div class="row">
        <div class="col-md-9 col-12">
          <a [routerLink]="product.id">
            <h5 class="ac-link">{{ product?.name }}</h5>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-12">
          <div class="ac-merchant-link">{{ merchant }}</div>
          <acx-product-attribute [attribute]="attribute1"></acx-product-attribute>
          <acx-product-attribute [attribute]="attribute2"></acx-product-attribute>
        </div>
        <div class="col-lg-5 col-12">
          <div class="row" *ngIf="collection">
            <div class="col">
              Collect at <strong>{{ collection }}</strong>
            </div>
          </div>
          <div class="row" *ngIf="delivery">
            <div class="col">
              Delivered in <strong>{{ delivery }}</strong> days
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-5 price-box">
      <div *ngIf="!isQuote; else quote">
        <label class="font-weight-bold">Price:</label>
        <span class="ac-price-label">
          {{ price | currency: 'ZAR':'symbol-narrow':'1.2-2' }}
          {{ price_unit }}
        </span>
      </div>
      <ng-template #quote>
        <label class="font-weight-bold">Price:</label>
        <span class="ac-price-label"> {{ price }} {{ price_unit }} </span>
      </ng-template>
      <div *ngIf="isShop">
        <label><strong>Stock:</strong> </label
        ><span class="stock-label" [ngClass]="{ 'out-of-stock-label': out_of_stock }"> {{ stock }}</span>
      </div>
      <div *ngIf="product?.batch_size" class="row">
        <div class="col">
          <span class="no-wrap"><label class="font-weight-bold">Batch Size:</label> {{ product?.batch_size }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span><label class="font-weight-bold">Min Qty:</label> {{ product?.order_size?.min }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span><label class="font-weight-bold">Max Qty:</label> {{ product?.order_size?.max }}</span>
        </div>
      </div>
      <button class="btn ac-btn-buy btn-block bg-primary" (click)="navigate()" [disabled]="!isEnabled">
        <i class="fas mr-2" [ngClass]="button.icon"></i>{{ button.label }}
      </button>
      <div (click)="disabledInfo()" *ngIf="!isEnabled" class="ac-link pt-2">Why can't I order this?</div>
    </div>
  </div>
</div>
