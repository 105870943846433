import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MerchantProfile } from 'core';

@Component({
  selector: 'app-merchant-info',
  templateUrl: './merchant-info.component.html',
  styleUrls: ['./merchant-info.component.scss']
})
export class MerchantInfoComponent implements OnInit {
  merchant: MerchantProfile;
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  close() {
    this.activeModal.close();
  }
}
