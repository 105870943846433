import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authorization/auth.guard';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderComponent } from './order/order.component';

const routes: Routes = [
  {
    path: 'account/orders',
    component: OrderListComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id',
        component: OrderComponent
      }
    ]
  },
  {
    path: 'order-completed',
    component: OrderConfirmationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule {}
