<div class="container">
  <h3 class="headings">Order</h3>
  <div *ngIf="order">
    <ngb-alert type="success" [dismissible]="false">
      Thank you. Your order has been received.
    </ngb-alert>
  </div>

  <div *ngIf="quote">
    <div class="row mb-5">
      <div class="col">
        <ngb-alert type="success" [dismissible]="false"
          >Quote request <strong>{{ quote ? '#' + quote.id : '' }}</strong> sent to
          <strong>{{ merchant?.name }}</strong>
        </ngb-alert>
      </div>
    </div>
  </div>

  <div *ngIf="order" class="mb-5">
    <div class="mb-5">
      <app-order-summary [order]="order"></app-order-summary>
    </div>
    <app-order [order]="order"></app-order>
  </div>
</div>
