import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomerProfile, ERROR, NotificationService } from 'core';
import { ValidatePasswords } from '../../registration/password.validator';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-accountinfo',
  templateUrl: './accountinfo.component.html',
  styleUrls: ['./accountinfo.component.scss']
})
export class AccountInfoComponent implements OnInit {
  readonly requiredError = ERROR.REQUIRED;

  accountForm = this.fb.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    updatePassword: [false],
    passwords: this.fb.group(
      {
        password: [''],
        confirmPassword: ['']
      },
      { validator: ValidatePasswords }
    )
  });

  constructor(private fb: FormBuilder, private user: UserService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.fetchProfile();
  }

  private fetchProfile() {
    this.user.getFullProfile().subscribe(
      res => {
        this.accountForm.get('name').setValue(res.first_name);
        this.accountForm.get('surname').setValue(res.last_name);
        this.accountForm.get('email').setValue(res.email);
        this.accountForm.get('updatePassword').setValue(false);
      },
      err => {
        console.log(err);
      }
    );
  }

  save() {
    const profile = {
      first_name: this.accountForm.controls['name'].value,
      last_name: this.accountForm.controls['surname'].value,
      password: this.updatePassword ? this.accountForm.controls['passwords'].value['password'] : null
    } as CustomerProfile;
    this.user.updateFullProfile(profile).subscribe(
      _ => {
        this.accountForm.get('passwords').reset();
        this.accountForm.get('updatePassword').setValue(false);
        this.notificationService.openSnackbar({
          data: {
            type: 'info',
            message: 'Your account was successfully updated'
          }
        });
      },
      e => {
        console.log(e);
        this.accountForm.get('passwords').reset();
        this.accountForm.get('updatePassword').setValue(false);
        this.notificationService.openSnackbar({
          data: {
            type: 'error',
            message: 'Something happened and your account was not updated'
          }
        });
      }
    );
  }

  get updatePassword() {
    return this.accountForm.get('updatePassword').value;
  }

  reset() {
    this.fetchProfile();
  }
}
