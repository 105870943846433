<div class="container">
  <div class="row">
    <h3 class="headings col-md">CREATE ACCOUNT</h3>
  </div>

  <div class="row mb-5">
    <div class="col">
      <div class="row my-2 justify-content-center">
        <div class="col-md-6">
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <input
                  (change)="onSwitch()"
                  class="form-check-input"
                  type="radio"
                  name="usertType"
                  value="customer"
                  checked
                  id="customerCheck"
                  [(ngModel)]="userType"
                />
                <label class="form-check-label" for="customerCheck">Customer Registration</label>
              </div>
            </div>

            <div class="col-6">
              <div class="form-check">
                <input
                  (change)="onSwitch()"
                  class="form-check-input"
                  type="radio"
                  name="usertType"
                  value="merchant"
                  id="merchantCheck"
                  [(ngModel)]="userType"
                />
                <label class="form-check-label" for="merchantCheck">Merchant Registration</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
