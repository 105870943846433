<h3 class="headings">Quotes</h3>
<ngb-alert type="info" [dismissible]="false"
  >A request for quote will be emailed to <strong>{{ merchant?.name }}</strong> when you place the order</ngb-alert
>
<table class="table table-bordered">
  <thead>
    <th>Product</th>
    <th>Qty</th>
    <th></th>
  </thead>
  <tbody>
    <tr *ngFor="let cartprod of products">
      <td>{{ cartprod.product.name }}</td>
      <td>{{ cartprod.qty }}</td>
      <td></td>
    </tr>
  </tbody>
</table>
