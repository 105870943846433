import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { OrderDetails, UserProfile } from 'core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CartService } from '../../checkout/cart.service';
import { Quote } from '../../model/quote';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {
  private _order: OrderDetails;
  private _merchant: UserProfile;
  private _quote: Quote;

  constructor(private orderService: OrderService, private route: ActivatedRoute, private cart: CartService) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        tap(params => {
          if (params.has('quote')) {
            this._quote = {} as Quote;
            this._quote.id = +params.get('quote');
            this._merchant = this.cart.merchant;
            this.cart.clear();
          }
        }),
        map((params: ParamMap) => +params.get('order')),
        filter(id => id !== 0),
        switchMap((id: number) => this.orderService.getOrderById(id))
      )
      .subscribe(order => {
        this._order = order;
        this._merchant = this.cart.merchant;
        // should clear cart if order succesfull
        this.cart.clear();
      });
  }

  get order(): OrderDetails {
    return this._order;
  }

  get merchant(): UserProfile {
    return this._merchant;
  }

  get quote(): Quote {
    return this._quote;
  }
}
