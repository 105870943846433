<div class="row d-flex flex-wrap flex-row justify-content-start p-3">
  <div *ngFor="let category of categories" class="p-1">
    <a class="ac-clickable" (click)="select(category)">
      <span
        [ngClass]="{
          'badge-primary': selectedCategory === category,
          'badge-secondary': selectedCategory !== category
        }"
        class="badge badge-pill"
      >
        {{ category }}
      </span>
    </a>
  </div>
</div>
