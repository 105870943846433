import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetails } from 'core';
import { UserService } from '../../user.service';
import { MerchantInfoComponent } from '../merchant-info/merchant-info.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  private _selected_order: OrderDetails;
  private _numberOfOrders: number;
  private _orders: OrderDetails[];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private user: UserService,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.orderService.getOrders().subscribe(orders => (this._orders = orders));
  }

  public selectOrder(order): void {
    this._selected_order = order;
    this.router.navigate(['/account/orders', order.id]);
  }

  get selected_order() {
    return this._selected_order;
  }

  open(order: OrderDetails) {
    if (order.merchant.id) {
      this._selected_order = order;
      this.user.getMerchantById(this.selected_order.merchant.id).subscribe(merchant => {
        const modalRef = this.modalService.open(MerchantInfoComponent);
        modalRef.componentInstance.merchant = merchant;
      });
    }
  }

  get numberOfOrders(): number {
    if (!this._numberOfOrders || this._numberOfOrders === 0) {
      this._numberOfOrders = this._orders ? this._orders.length : undefined;
    }
    return this._numberOfOrders;
  }

  get orders() {
    return this._orders;
  }
}
