import { Injectable } from '@angular/core';
import {
  ApiService,
  Endpoints,
  Namespace,
  OrderDetails,
  OrderRequest,
  OrderResponse,
  OrderSummary,
  PaymentInfo
} from 'core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _orders: OrderDetails[];

  constructor(private api: ApiService) {}

  public getOrderById(id: number): Observable<OrderDetails> {
    const fetch$ = this.api
      .get(Namespace.ac, Endpoints.customer_orders + '/' + id)
      .pipe(map((order: OrderDetails) => this.mapOrder(order)));

    if (this._orders && this._orders.length > 0) {
      const ix = this._orders.findIndex(ord => ord.id === id);
      return ix > -1 ? of(this._orders[ix]) : fetch$;
    }
    return fetch$;
  }

  getOrders(): Observable<OrderDetails[]> {
    return this.api.get(Namespace.ac, Endpoints.customer_orders).pipe(
      map((orders: OrderDetails[]) => orders.map(o => this.mapOrder(o))),
      tap((orders: OrderDetails[]) => (this._orders = orders))
    );
  }

  get orders(): OrderDetails[] {
    return this._orders;
  }

  placeOrder(order: OrderRequest): Observable<OrderResponse> {
    // place order
    return this.api.post(Namespace.ac, Endpoints.customer_orders, order);
  }

  retryPayment(id: number): Observable<PaymentInfo> {
    return this.api.get(Namespace.ac, `${Endpoints.retry_payment}/${id}`);
  }

  private mapOrder(order: OrderDetails): OrderDetails {
    order.order = new OrderSummary(order.order.items, order.order.delivery_total, order.order.delivery_vat);
    return order;
  }
}
