<div class="container">
  <div class="row mb-5">
    <div class="col-md-6 col-12">
      <form [formGroup]="accountForm" (ngSubmit)="save()">
        <div class="form-group">
          <label class="headings">First Name:</label>
          <input class="form-control col-12" formControlName="name" placeholder="e.g. AB" required />
          <div *ngIf="accountForm.invalid && (accountForm.dirty || accountForm.touched)" class="text-danger">
            <div *ngIf="accountForm.get('name').invalid">
              {{ requiredError }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="headings">Last Name:</label>
          <input class="form-control col-12" formControlName="surname" placeholder="e.g. AB" required />
          <div *ngIf="accountForm.invalid && (accountForm.dirty || accountForm.touched)" class="text-danger">
            <div *ngIf="accountForm.get('surname').invalid">
              {{ requiredError }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="headings">Email Address:</label>
          <input class="form-control" formControlName="email" placeholder="e.g. name@domain.com" readonly />
        </div>
        <div class="form-group">
          <label class="headings">
            <input type="checkbox" formControlName="updatePassword" /> I would like to change my password.
          </label>
        </div>
        <div *ngIf="updatePassword">
          <div formGroupName="passwords">
            <div class="form-group">
              <label class="headings">New Password:</label>
              <input
                type="password"
                class="form-control"
                formControlName="password"
                placeholder="e.g. xxxxxxxxx"
                minlength="6"
              />
              <div *ngIf="accountForm.invalid && (accountForm.dirty || accountForm.touched)" class="text-danger">
                <div *ngIf="accountForm.get('passwords.password').hasError('required')">
                  {{ requiredError }}
                </div>
                <div *ngIf="accountForm.get('passwords.password').hasError('minlength')">
                  Your password needs to be at least 6 characters long
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="headings">Confirm Password:</label>
              <input
                type="password"
                class="form-control"
                formControlName="confirmPassword"
                placeholder="e.g. xxxxxxxxx"
              />
              <div *ngIf="accountForm.invalid && (accountForm.dirty || accountForm.touched)" class="text-danger">
                <div *ngIf="accountForm.get('passwords.confirmPassword').invalid">
                  {{ requiredError }}
                </div>
                <div
                  *ngIf="accountForm.get('passwords').invalid && !accountForm.get('passwords.confirmPassword').invalid"
                >
                  Your password doesn't match
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-0 col-md"></div>
          <div class="col-6 col-md-3">
            <button
              type="button"
              (click)="reset()"
              [disabled]="accountForm.pristine"
              class="btn ac-btn-primary btn-block"
            >
              Cancel
            </button>
          </div>
          <div class="col-6 col-md-3">
            <button type="submit" [disabled]="accountForm.invalid" class="btn ac-btn-primary btn-block">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
