import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvertiseComponent } from './advertise/advertise.component';
import { ContactComponent } from './contact/contact.component';
import { HomepageComponent } from './homepage/homepage/homepage.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { ResetComponent } from './reset/reset.component';
import { SignInComponent } from './signin/signin.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomepageComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'advertise-with-us', component: AdvertiseComponent },
  { path: 'signin', component: SignInComponent },
  { path: 'reset', redirectTo: 'home', pathMatch: 'full' },
  { path: 'reset_password', component: ResetComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
