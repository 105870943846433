import { DeliveryMethod, Dictionary, IDictionary, IProduct, UserProfile } from 'core';

export interface CartItem {
  product_id: number;
  qty: number;
}

export interface CartProduct {
  qty: number;
  product: IProduct;
}

interface ICart {
  items: IDictionary<CartItem>;
  count: number;

  merchant: UserProfile;
  add(item: CartItem);
  remove(item_id: number);
  update(item_id: number, qty: number);
  updateMerchant(merchant: UserProfile);
  isSameMerchant(prod: IProduct): boolean;
}

export class Cart implements ICart {
  items: IDictionary<CartItem>;
  merchant: UserProfile;

  constructor() {
    this.items = new Dictionary();
  }

  get count(): number {
    return this.items
      .values()
      .map(i => i.qty)
      .reduce((count, qty) => (count += qty), 0);
  }

  add(item: CartItem, merchant?: UserProfile, delivery?: Set<DeliveryMethod>) {
    this.merchant = merchant;
    if (this.items.containsKey(item.product_id.toString())) {
      this.items.item(item.product_id.toString()).qty += item.qty;
    } else {
      this.items.add(item.product_id.toString(), item);
    }
  }

  remove(id: number) {
    this.items.remove(id.toString());
    if (this.items.count < 1) {
      this.clear();
    }
  }

  removeAll() {
    this.items = new Dictionary();
    this.clear();
  }

  update(id, qty: number) {
    this.items.item(id.toString()).qty = +qty;
  }

  updateMerchant(merchant: UserProfile) {
    this.merchant = merchant;
  }

  isSameMerchant(product: IProduct): boolean {
    return !!this.merchant ? product.merchant.id === this.merchant.id : true;
  }

  private clear() {
    this.merchant = undefined;
  }
}
