<div class="container">
  <h3 class="headings">CART</h3>
  <div class="row my-3">
    <div class="col">
      <ngb-alert type="success" [dismissible]="false">
        <i class="fas fa-check-circle ac-icon"></i> You have added {{ count }}
        {{ count === 1 ? 'product' : 'products' }} to your cart.
        <strong>
          <a routerLink="/products">Continue Shopping</a>
        </strong>
      </ngb-alert>
      <ngb-alert *ngIf="!hasStock" type="danger" [dismissible]="false">
        <i class="fas fa-check-circle ac-icon"></i> {{ outOfStockCount }}
        {{ outOfStockCount === 1 ? 'item in your cart exceeds' : 'items in your cart exceed' }} available stock. Please
        reduce their quantities or
        <strong>
          <a (click)="removeAllOutOfStockItems()" href="javascript:;">remove them from your cart.</a>
        </strong>
      </ngb-alert>
    </div>
  </div>
  <div *ngIf="alert?.showAlert">
    <ngb-alert [type]="alert?.type" [dismissible]="true" (close)="alert.showAlert = false">{{
      alert?.message
    }}</ngb-alert>
  </div>

  <div *ngIf="hasQuotes" class="row mb-5">
    <div class="col">
      <h5>Quotes</h5>
      <table class="table">
        <thead>
          <th></th>
          <th>Product</th>
          <th></th>
          <th>Quantity</th>
          <th>Unit</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let cartprod of quote_products">
            <td>
              <div *ngIf="getProductImage(cartprod.product); else default">
                <img
                  class="img-thumbnail d-none d-md-block"
                  [routerLink]="cartprod.product.id"
                  [src]="getProductImage(cartprod.product)"
                  alt="Logo"
                />
              </div>
              <ng-template #default>
                <i class="fas fa-box-open fa-5x"></i>
              </ng-template>
            </td>
            <td>{{ cartprod.product.name }}</td>
            <td></td>
            <td><input class="form-control col-lg-auto" type="number" [value]="cartprod.qty" min="1" /></td>
            <td>{{ cartprod.product.unit_size }} {{ cartprod.product.unit }}</td>
            <td><i class="fas fa-trash-alt ac-clickable" (click)="remove(cartprod.product)"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="hasCheckouts" class="row">
    <div class="col table-responsive-sm">
      <h5>Items for Checkout</h5>
      <table class="table">
        <thead>
          <th class="d-none d-lg-table-cell"></th>
          <th>Product</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Unit</th>
          <th>Total (Excl. VAT)</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let cartprod of shop_products">
            <td class="d-none d-lg-table-cell">
              <div *ngIf="getProductImage(cartprod.product); else default">
                <img
                  class="img-thumbnail d-none d-md-block"
                  [routerLink]="cartprod.product.id"
                  [src]="getProductImage(cartprod.product)"
                  alt="Logo"
                />
              </div>
              <ng-template #default>
                <i class="fas fa-box-open fa-5x"></i>
              </ng-template>
            </td>
            <td>{{ cartprod.product.name }}</td>
            <td>{{ cartprod.product.price.value?.price_excl_vat | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
            <td>
              <input
                class="form-control col-lg-auto"
                type="number"
                #qty
                [value]="cartprod.qty"
                [min]="cartprod.product.batch_size || cartprod.product.order_size.min"
                [step]="cartprod.product.batch_size || 1"
                [max]="cartprod.product.order_size?.max"
                (click)="update(cartprod.product, qty.value)"
                (blur)="update(cartprod.product, qty.value)"
              />
              <span *ngIf="!hasStockFor(cartprod)" class="stock-label" [ngClass]="{ 'out-of-stock-label': true }"
                >{{ stockAvailabilityLabelFor(cartprod) }}
                <br />
                <strong>
                  <a
                    *ngIf="hasInsufficientStockFor(cartprod); else removeLink"
                    (click)="update(cartprod.product, cartprod.product.stock)"
                    href="javascript:;"
                    >Reduce Qty</a
                  >
                  <ng-template #removeLink>
                    <a (click)="remove(cartprod.product)" href="javascript:;">Remove</a>
                  </ng-template>
                </strong>
              </span>
            </td>
            <td>{{ cartprod.product.unit_size }} {{ cartprod.product.unit }}</td>
            <td>
              {{
                cartprod.product.price.value?.price_excl_vat * cartprod.qty | currency: 'ZAR':'symbol-narrow':'1.2-2'
              }}
            </td>
            <td><i class="fas fa-trash-alt ac-clickable" (click)="remove(cartprod.product)"></i></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="d-none d-lg-table-cell"></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Total</th>
            <th colspan="2">{{ subtotal | currency: 'ZAR':'symbol-narrow':'1.2-2' }}<a>*</a></th>
          </tr>
        </tfoot>
      </table>
      <span class="float-right">* Excludes delivery fee</span>
    </div>
  </div>

  <div class="row my-5">
    <div class="col">
      <button class="btn ac-btn-primary float-right" (click)="checkout()" [disabled]="!valid">Checkout</button>
    </div>
  </div>
</div>
