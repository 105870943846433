<div class="row">
  <div class="col-md-4">
    <h3 class="headings">Billing Address</h3>
    <div class="col">
      <div class="row">
        <label>{{ billing?.company || billing?.first_name + ' ' + billing?.last_name }}</label>
      </div>
      <div class="row">
        <label>{{ billing?.address?.address_1 }}</label>
      </div>
      <div *ngIf="billing?.address?.address_2" class="row">
        <label>{{ billing?.address?.address_2 }}</label>
      </div>
      <div class="row">
        <label>{{ billing?.address?.city }}</label>
      </div>
      <div class="row">
        <label>{{ billing?.address?.postal_code }}</label>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <h3 class="headings">{{ collection ? 'Collection' : 'Delivery' }} Address</h3>
    <div class="row">
      <div class="col-md mb-3">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="16">
          <agm-marker [latitude]="latitude" [longitude]="longitude"> </agm-marker>
        </agm-map>
      </div>
      <div *ngIf="collection; else delivery" class="col-md ml-3">
        <div class="row">
          <label>{{ collection_info?.name }}</label>
        </div>
        <div class="row">
          <label>{{ collection_info?.address?.address_1 }}</label>
        </div>
        <div *ngIf="collection_info?.address?.address_2" class="row">
          <label>{{ collection_info?.address?.address_2 }}</label>
        </div>
        <div class="row">
          <label>{{ collection_info?.address?.city }}</label>
        </div>
        <div class="row">
          <label>{{ collection_info?.address?.postal_code }}</label>
        </div>
        <div class="row">
          <label><strong>Operating Hours:</strong></label>
        </div>
        <div *ngFor="let tradingHours of collection_info?.hours" class="row">
          <label
            >{{ tradingHours.start_day }} {{ tradingHours?.end_day ? ' - ' + tradingHours?.end_day : '' }} :
            {{ tradingHours.start_time }} - {{ tradingHours.end_time }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #delivery>
  <div class="col-md ml-3">
    <div class="row">
      <label>{{ shipping?.company || shipping?.first_name + ' ' + shipping?.last_name }}</label>
    </div>
    <div class="row" *ngIf="shipping?.tel">
      <label><i class="fas fa-phone"></i> {{ shipping?.tel }}</label>
    </div>
    <div class="row">
      <label>{{ shipping?.address.address_1 }}</label>
    </div>
    <div *ngIf="shipping?.address.address_2" class="row">
      <label>{{ shipping?.address.address_2 }}</label>
    </div>
    <div class="row">
      <label>{{ shipping?.address.city }}</label>
    </div>
    <div class="row">
      <label>{{ shipping?.address.postal_code }}</label>
    </div>
    <div class="row">
      <label>{{ shipping?.address.municipality }}</label>
    </div>
    <div class="row">
      <label>{{ shipping?.address.province }}</label>
    </div>
  </div>
</ng-template>
