import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Alert,
  CityLookupService,
  IProduct,
  isNumberFieldType,
  ProductCategory,
  ProductType,
  Province,
  Range,
  PaginationParams
} from 'core';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, first } from 'rxjs/operators';
import { CartService } from '../../checkout/cart.service';
import {
  DefaultFilterFields,
  ProductFilterParams,
  StaticFilterParams,
  DynamicFilterParams,
  SearchParams
} from '../../model/product';
import { CategoryMapper } from '../category-mapper';
import { DynamicControl } from '../dynamic-control';
import { ProductService } from '../product.service';

/*******************************************************************************************************
 * FLOW DIAGRAM
 *
 *                         User Input
 *                             ↓
 * INIT → Get Categories → Update Route → Set Defaults → Create filter form → Update query params → Retrieve Products
 *
 * User Input :  * Change Page
 *               * Change Filter (reset pagination)
 *               * Change Category (reset pagination) (clear filter)
 *               * Change URL (apply query params and defaults)
 *               * Search (reset all)
 *
 ********************************************************************************************************/

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit, OnDestroy {
  products: IProduct[] = [];

  alert: Alert;

  private subs: Subscription[];
  private search$ = new Subject<ProductFilterParams>();

  private _municipalities: string[];
  private _categories: ProductCategory[];
  private _productCount: number;
  private UIOffset: string | null;

  private _price;
  private category: ProductCategory;
  selectedCategory: string;
  private _staticFilterParams: StaticFilterParams;
  private _dynamicFilterParams: DynamicFilterParams;
  private _searchParams: SearchParams;
  private _paginationParams: PaginationParams;

  private mapper: CategoryMapper;

  controls: DynamicControl<boolean | Range | number>[] = [];
  filter_form = this.fb.group({
    province: [''],
    municipality: [''],
    type: [''],
    price: [''],
    array: this.fb.array([])
  });

  constructor(
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private cart: CartService,
    private fb: FormBuilder,
    private cityService: CityLookupService
  ) {
    this.subs = new Array();
    this._staticFilterParams = {};
    this._dynamicFilterParams = {};
    this._searchParams = {};
    this._paginationParams = {
      offset: '0',
      limit: '10'
    };
    this.mapper = new CategoryMapper();
    this._categories = [];
    this._productCount = 0;
  }

  ngOnInit() {
    this.subs.push(
      this.productService.getMaxProductPrice().subscribe(max => {
        this.initialize(max);
      })
    );

    this.subs.push(
      this.search$.pipe(debounceTime(250)).subscribe((params: ProductFilterParams) => {
        this.router.navigate(['/products'], {
          queryParams: params
        });
      })
    );

    this.subs.push(
      this.productService.getCategories().subscribe(categories => {
        this._categories = categories;
        if (!this.category && this._staticFilterParams.category && this._categories.length > 0) {
          this.category = this._categories.find(c => c.name === this._staticFilterParams.category);
          if (this.category) {
            this.createFilterFields(this.category);
          }
        }
      })
    );

    const queryObs$ = this.route.queryParamMap;
    this.subs.push(
      queryObs$.subscribe(params => {
        const queryParams = { ...params['params'] };

        const categoryUpdated = this.applyCategory(queryParams);
        this.applyFilterForm(queryParams);
        this.applySearch(queryParams);
        this.applyPagination(queryParams);

        this.applyPrice(queryParams);
        if (categoryUpdated) this.createFilterFields(this.category);
      })
    );

    this.subs.push(
      queryObs$
        .pipe(
          debounceTime(250),
          switchMap(params => {
            const { ...paramas } = params['params'];
            return this.productService.retrieveProducts(paramas as ProductFilterParams);
          })
        )
        .subscribe(prods => {
          this.products = prods.results;
          this._productCount = prods.matches;
        })
    );

    this.subs.push(
      this.cart.getCartProducts().subscribe(
        _ => true,
        err => console.error(err)
      )
    );

    this.subs.push(
      this.filter_form
        .get('province')
        .valueChanges.pipe(
          switchMap(prov => {
            return prov === DefaultFilterFields.all_provinces
              ? of([])
              : this.cityService.getMunicipalitiesInProvince(prov);
          })
        )
        .subscribe((munic: string[]) => {
          this._municipalities = munic;
        })
    );

    this.subs.push(
      this.filter_form.valueChanges
        .pipe(map(values => this.mapper.mapToQueryParams({ ...values }, this.category)))
        .subscribe(params => {
          let queryParams = { ...params };

          //remove "" values
          queryParams = JSON.parse(JSON.stringify(queryParams, (_key, value) => (value === '' ? undefined : value)));

          const shouldResetPage = this.UIOffset === this._paginationParams.offset;
          this.UIOffset = null;

          queryParams = {
            ...this._searchParams,
            ...this._staticFilterParams,
            ...this._paginationParams,
            ...this._dynamicFilterParams,
            offset: shouldResetPage ? '0' : this._paginationParams.offset,
            ...queryParams
          };

          this.router.navigate(['/products'], {
            queryParams: { ...queryParams }
          });
        })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) sub.unsubscribe();
    });
  }

  applyPagination(filter: ProductFilterParams) {
    const { offset, limit } = filter;
    this._paginationParams = { offset, limit };
    if (!offset) this._paginationParams.offset = '0';
    if (!limit) this._paginationParams.limit = '10';
  }

  applyCategory(filter: ProductFilterParams) {
    this._staticFilterParams.category = filter.category;
    const newCategory = this._categories.find(c => c.name === this._staticFilterParams.category);
    const categoryUpdated =
      !!this.category !== !!newCategory || (this.category && newCategory && this.category.id !== newCategory.id);
    this.category = newCategory;
    this.selectedCategory = this.category?.name;
    return categoryUpdated;
  }

  applySearch(filter: ProductFilterParams) {
    this._searchParams.search_string = filter.search_string;
  }

  private applyPrice(filter: ProductFilterParams) {
    if (!filter.price) return;
    const [low, high] = (filter.price as any) as [string, string];
    if (this._price)
      this._price = {
        ...this._price,
        value: low,
        highvalue: high
      };
  }

  applyFilterForm(filter: ProductFilterParams) {
    const { category, municipality, province, type, search_string, price, offset, limit, ...dynamic } = filter;
    this._staticFilterParams = {
      category,
      municipality,
      province,
      type,
      price
    };
    this._dynamicFilterParams = dynamic;
  }

  selectCategory(category: ProductCategory) {
    this.search$.next(this._staticFilterParams.category === category?.name ? {} : { category: category?.name });
  }

  updatePage(pageNumber: number) {
    const { limit } = this._paginationParams;
    const offset = ((pageNumber - 1) * parseInt(limit, 10)).toString();
    this.UIOffset = offset;
    const queryParams = {
      ...this.filter,
      offset,
      limit
    };
    this.router.navigate(['/products'], {
      queryParams
    });
  }

  async initialize(max: number) {
    const priceRange = (this._staticFilterParams.price as [any, any]) || [0, max];
    const low = parseInt(priceRange[0], 10);
    const high = parseInt(priceRange[1], 10);

    if (high >= 0 && low >= 0)
      this._price = {
        highvalue: high > 100 ? high : 100,
        value: low,
        options: {
          ceil: max > 100 ? max * 1.2 : 120,
          step: 100,
          floor: 0
        }
      };

    const or = (v?: string, defaultV?: string) => (!!v && v !== '' ? v : defaultV);

    const type = or(this._staticFilterParams.type, DefaultFilterFields.any_filter);
    const province = or(this._staticFilterParams.province, DefaultFilterFields.all_provinces);
    const municipality = or(this._staticFilterParams.municipality, DefaultFilterFields.any_filter);

    if (province && province !== DefaultFilterFields.all_provinces) {
      this.cityService
        .getMunicipalitiesInProvince(province)
        .pipe(first())
        .subscribe(municipalities => {
          this._municipalities = municipalities;
        });
    }

    this.filter_form.get('type').patchValue(type, { emitEvent: false });

    this.filter_form.get('province').patchValue(province, { emitEvent: false });

    this.filter_form.get('municipality').patchValue(municipality, { emitEvent: false });
  }

  private createFilterFields(category: ProductCategory) {
    if (category && category.fields) {
      category = this.mapper.mutateCategory(category);
    }
    this.form_array.clear();

    if (category && category.fields) {
      category.fields.forEach(field => {
        // set the filter default values
        let _default: any = DefaultFilterFields.any_filter;
        if (isNumberFieldType(field.type)) {
          _default = undefined;
        }

        if (field.type === 'boolean') {
          _default = false;
        }

        this.form_array.push(this.fb.control(this.filter[field.name] || _default));
      });
    } else {
      // trigger valueChanges event
      this.filter_form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
    this.controls = this.mapper.buildFormArray(category);
  }

  get filter(): ProductFilterParams {
    return {
      ...this._staticFilterParams,
      ...this._dynamicFilterParams,
      ...this._paginationParams,
      ...this._searchParams
    };
  }

  get provinces(): string[] {
    return [DefaultFilterFields.all_provinces, ...Object.values(Province)];
  }

  get municipalities(): string[] {
    return [DefaultFilterFields.any_filter, ...(this._municipalities ? this._municipalities : [])];
  }

  get product_types(): string[] {
    return [DefaultFilterFields.any_filter, ...Object.values(ProductType)];
  }

  private get form_array(): FormArray {
    return this.filter_form.get('array') as FormArray;
  }

  get price() {
    return this._price;
  }

  get currentPage() {
    const { offset, limit } = this._paginationParams;
    return Math.floor((parseInt(offset, 10) || 0) / parseInt(limit, 10)) + 1;
  }

  get pageLimit() {
    return parseInt(this._paginationParams.limit, 10);
  }

  get productCount() {
    return this._productCount;
  }
}
