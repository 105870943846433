import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderDetails, DeliveryMethod, OrderStatus } from 'core';
import { map, switchMap, filter, first, withLatestFrom } from 'rxjs/operators';
import { OrderService } from '../order.service';
import { PaymentService } from '../../checkout/payment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'core';
import { from } from 'rxjs';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  private _order: OrderDetails;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private modal: NgbModal,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map(params => +params.get('id')),
        filter(id => id !== 0),
        switchMap(id => this.orderService.getOrderById(id))
      )
      .subscribe(order => {
        this._order = order;
      });
  }

  @Input()
  set order(val: OrderDetails) {
    if (val) {
      this._order = val;
    }
  }

  get order() {
    return this._order;
  }

  get statusFailed() {
    return this._order && this._order.status === OrderStatus.failed;
  }

  get delivered() {
    return this._order && this._order.delivery_method === DeliveryMethod.Delivery;
  }

  get delivery_date() {
    return this._order.delivery_info.delivery_date;
  }

  retryPayment() {
    if (!this._order.id) return;

    const ref = this.modal.open(ConfirmDialogComponent);
    ref.componentInstance.title = 'Before you do...';
    ref.componentInstance.message = `Are you sure that you want to retry payment for order ${this._order.id}`;
    ref.componentInstance.continueText = 'Make Payment';

    ref.result.then(
      res =>
        res &&
        this.orderService
          .retryPayment(this._order.id)
          .pipe(first())
          .subscribe({
            next: paymentInfo => {
              this.paymentService.pay(paymentInfo);
            }
          })
    );
  }
}
