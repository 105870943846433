import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit {
  rateCard = 'https://downloads.agrichecker.com/assets/ads/Agrichecker-Rate-Card-latest.pdf';

  constructor() {}

  ngOnInit() {}
}
