import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';

enum UserType {
  customer = 'customer',
  merchant = 'merchant'
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  userType: UserType = UserType.customer;

  constructor(private router: Router) {
    //onInit is not neccesarily called when a sub component is created
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        const routes = event.snapshot.routeConfig.path;
        if (routes === UserType.merchant) {
          this.userType = UserType.merchant;
        } else if (routes === UserType.customer) {
          this.userType = UserType.customer;
        }
      }
    });
  }

  ngOnInit() {}

  onSwitch() {
    this.router.navigateByUrl('register/' + this.userType);
  }
}
