import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidatePasswords } from '../registration/password.validator';
import { ApiService, Namespace } from 'core';
import { ApiResponse, AuthService } from '../authorization/auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  token: string;
  email: string;

  resetForm = this.fb.group(
    {
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
    { validator: ValidatePasswords }
  );

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.token = this.route.snapshot.queryParamMap.get('token');

    this.api.get(Namespace.ac, '/reset_password?token=' + this.token + '&email=' + this.email).subscribe(
      (res: ApiResponse) => {},
      (err: any) => {
        this.token = undefined;
      }
    );
  }

  onSubmit() {
    this.auth.resetPassword(this.resetForm.controls['password'].value, this.email).subscribe(
      (res: ApiResponse) => {
        this.router.navigate(['/signin']);
      },
      (err: any) => {
        this.token = undefined;
      }
    );
  }
}
