import { Component, OnInit } from '@angular/core';
import { FooterLinks } from 'core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly BLOG_URL = environment.blog_url;
  private readonly ABOUT_URL = environment.about_us_url;

  footer_links: FooterLinks = {
    register: {
      title: 'Register',
      url: '/register'
    },
    advertise: {
      title: 'Advertise with us',
      url: '/advertise-with-us'
    },
    what_is_agrichecker: {
      title: 'What is Agrichecker?',
      url: this.ABOUT_URL,
      external: true
    },
    blog: {
      title: 'Blog',
      url: this.BLOG_URL,
      external: true
    },
    contact_us: {
      title: 'Contact us',
      url: '/contact-us'
    }
  };

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-125972822-2', {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }
}
