<div class="modal-header">
  <h4>Agrichecker Terms and Conditions</h4>
</div>
<div class="modal-body">
  <p>WEBSITE TERMS AND CONDITIONS OF USE</p>
  <p>1. Application</p>
  <p>
    1.1 This document sets out the terms and conditions (&ldquo;Terms&rdquo;) of Agrichecker (Pty) Ltd, registration
    number 2017/108629/07, with registered address situated at 10 Du Plessis Street, Humansdorp
    (&ldquo;Agrichecker&rdquo;) pertaining to the listing or advertising of products or services; the access and use of
    the information, services and functions provided; and the purchasing of products or services on this website.
  </p>
  <p>
    1.2 Agrichecker renders the service of hosting an online listing, search and purchasing portal, with its primary
    focus on the agricultural industry.
  </p>
  <p>
    1.3 You may only use this website to list or advertise products or services, to browse the content, to make
    legitimate sales and purchases and shall not use the website for any other purpose.
  </p>
  <p>
    1.4 Should you disagree with any of the Terms, you must refrain from accessing the website and/or using the services
    of Agrichecker.
  </p>
  <p>
    1.5 If you are under the age of eighteen, you must obtain your parents&rsquo; or legal guardian&rsquo;s advance
    authorization, permission and consent to be bound by these Terms before accessing the website and/or using the
    services of Agrichecker.
  </p>
  <p>
    1.6 If there is anything in these Terms that you do not understand, please contact us as soon as possible. You can
    refer to clause 10 below for our contact details. Please note that calls to us are charged at national rates and may
    be monitored for training, security and quality assurance purposes.
  </p>
  <p>2. Content of website</p>
  <p>
    2.1 The contents of this website are provided &ldquo;as is&rdquo; and Agrichecker and its online partners shall not
    be liable for any losses or damages that may arise from your reliance on it, howsoever these may arise.
  </p>
  <p>
    2.2 Agrichecker makes no representations or warranties, whether express or implied, as to the accuracy, completeness
    or reliability of any information, data and/or contents on the website, including without limitation:-
  </p>
  <p>
    2.2.1 Agrichecker does not warrant that the website or information or downloads shall be error free or that they
    shall meet any particular criteria of performance or quality. Agrichecker expressly disclaims all implied
    warranties, including without limitation, warranties of merchantability, fitness for a particular purpose,
    non-infringement, compatibility, security and accuracy;
  </p>
  <p>
    2.2.2 whilst Agrichecker has taken reasonable measures to ensure the integrity of the website and its contents, no
    warranty, whether express or implied, is given that any files, downloads or applications available via this website
    are free of viruses, or any other data or code which has the ability to corrupt, damage or affect the operation of
    your system; and
  </p>
  <p>
    2.2.3 Agrichecker disclaims any responsibility for the verification of any claims. Information published on this
    website may be done in the format in which Agrichecker receives it and statements from third parties are accepted as
    fact.
  </p>
  <p>
    2.3 Agrichecker provides third parties in the agricultural industries (&ldquo;third party providers&rdquo;) with a
    platform to market and sell their products and services to users of this website (&ldquo;users&rdquo;). Agrichecker
    has no control over the information provided by the third party providers and makes no representations or warranties
    of any nature as to its accuracy, appropriateness or correctness. Users agree that such information is provided
    &ldquo;as is&rdquo; and that Agrichecker shall not be liable for any losses or damages that may arise from
    users&rsquo; reliance on it, howsoever these may arise.
  </p>
  <p>
    2.4 The contract for the provision of the products and services of such third party providers is concluded between
    the user and the third party provider and any right of recourse that the user may have will be solely against such
    third party provider and vice versa. Agrichecker does not warrant, guarantee or make any representations regarding
    the description, safety, reliability, accuracy, correctness, fitness, quality or completeness of such products or
    services, nor to the ability of the users to pay for the products or services. Agrichecker does not represent or
    hold itself out as the manufacturer or provider of the products or services listed on this website, nor as the agent
    or representative of any user or third party provider.
  </p>
  <p>
    2.5 Agrichecker reserves the right to make improvements, to change or to discontinue, without notice, any aspect or
    feature of the website and any
  </p>
  <p>
    information or content on the website. Agrichecker will not be liable for any compensation or damages because you
    cannot use any part of the website or because of a failure, suspension or withdrawal of all or part of the website.
  </p>
  <p>3. Linked third party websites and third party content</p>
  <p>
    3.1 Agrichecker may provide links to third party websites on this website. These links are provided to you for
    convenience purposes only and Agrichecker does not endorse, nor does the inclusion of any link imply
    Agrichecker&rsquo;s endorsements of, such websites, their owners, licensees or administrators or such
    websites&rsquo; content or security practices and operations.
  </p>
  <p>
    3.2 While Agrichecker tries to provide links only to reputable websites or third party providers, Agrichecker cannot
    accept responsibility or liability for the information provided on other websites. Linked websites or pages are not
    under, nor subject to, the control of Agrichecker. Agrichecker is not responsible for and gives no warranties or
    makes any representations in respect of the privacy policies or practices of linked or any third party or advertised
    websites on this website.
  </p>
  <p>
    3.3 You agree that Agrichecker shall not be held liable, directly or indirectly, in any way for the content, the use
    or inability to use or access to any linked website or any links contained in a linked website, nor for any loss or
    damage of any kind incurred as a result of any dealings with, or as a result of the presence of such third party
    linked websites on this website. Any dealings that you may have with any linked websites, including advertisers,
    found on this website, are solely between you and the third party website.
  </p>
  <p>4. Usage restriction</p>
  <p>You agree that you shall not yourself, nor through a third party:-</p>
  <p>
    4.1 copy (other than for backup, archival or disaster recovery purposes), reproduce, translate, adapt, vary, modify,
    lease, license, sub-license, encumber or in any other way deal with any part of this website for any reason and in
    any manner, unless it is consistent with the intent and purpose of these Terms;
  </p>
  <p>4.2 decompile, disassemble or reverse engineer any portion of the website;</p>
  <p>4.3 write and/or develop any derivative of the website or any software program based on the website;</p>
  <p>
    4.4 modify or enhance the website. In the event of you affecting any modifications or enhancements to the website in
    breach of this clause, such modifications and enhancements shall be the property of Agrichecker;
  </p>
  <p>
    4.5 without Agrichecker&rsquo;s prior written consent, provide, disclose, divulge or make available to or permit the
    use of or give access to the website by any person other than the user or the third party provider;
  </p>
  <p>4.6 remove any identification, trademark, copyright or other notices from the website;</p>
  <p>
    4.7 post or transmit, by means of reviews, comments, suggestions, ideas, questions or other information through the
    website, any content which is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene,
    sexually explicit, profane or hateful, or racially, ethnically or otherwise objectionable content of any kind;
    and/or
  </p>
  <p>
    4.8 notwithstanding anything contained to the contrary in these Terms, use the website for any purpose other than
    set out in clause 1.1.
  </p>
  <p>5. Security</p>
  <p>
    5.1 Agrichecker hereby reserves the right to take whatever action it may deem necessary to preserve the security,
    integrity and reliability of its network and back-office applications.
  </p>
  <p>
    5.2 You may not utilize the website in any manner which may compromise the security of Agrichecker&rsquo;s networks
    or tamper with the website in any manner whatsoever, which shall include without limitation, gaining or attempting
    to gain unauthorized access to the website, or delivering or attempting to deliver any unauthorized, damaging or
    malicious code to the website, all of which is expressly prohibited. Any person or entity which does so, or attempts
    to do so, shall be held criminally liable. Further, should Agrichecker suffer any damage or loss, civil damages
    shall be claimed by Agrichecker against the user and/or third party provider.
  </p>
  <p>
    5.3 Any user or third party provider who commits any of the offences detailed in Chapter 13 of the Electronic
    Communications and Transactions Act 25 of 2002
  </p>
  <p>
    (&ldquo;ECTA&rdquo;) shall, notwithstanding criminal prosecution, be liable for all resulting liability, loss or
    damages suffered and/or incurred by Agrichecker and its affiliates, agents and/or partners.
  </p>
  <p>6. Intellectual property rights</p>
  <p>
    6.1 For the purpose of this clause, &ldquo;intellectual property rights&rdquo; means all and any of the rights in
    and to intellectual property of any nature whatsoever owned and/or controlled directly or under license by
    Agrichecker, now or in the future, including without limitation, Agrichecker&rsquo;s rights, title and interest in
    and to all technology, source codes, trade secrets, logos, systems, methods, trademarks, trade names, styles,
    insignia, designs, patents and copyright and all similarly proprietary rights which may subsist in any part of the
    world, whether registered or not.
  </p>
  <p>
    6.2 All copyright and other intellectual property rights in all content, trademarks, software, data, material,
    including logos, databases, text, graphics, icons, hyperlinks, confidential information, designs, agreements and
    multimedia works, published on or via the website (&ldquo;proprietary material&rdquo;) are the property of, or are
    licensed to Agrichecker and as such are protected from infringement by local and international legislation and
    treaties.
  </p>
  <p>
    6.3 By submitting listings, reviews, comments and/or other content (other than your personal information) to
    Agrichecker for posting on the website, you automatically grant Agrichecker and affiliates a non-exclusive,
    royalty-free, perpetual, irrevocable right and license to use, reproduce, publish, translate, sub-license, copy and
    distribute such content in whole or in part worldwide, and
  </p>
  <p>
    to incorporate it in other works in any form, media or technology now known or hereinafter developed, for the full
    term of any copyright that may exist in such content. Subject to this license, you retain any and all rights that
    may exist in such content.
  </p>
  <p>
    6.4 All rights not expressly granted are reserved and no right, title or interest in any proprietary material or
    information contained in this website is granted to you.
  </p>
  <p>
    6.5 Except with Agrichecker&rsquo;s express written permission, no proprietary material from this website may be
    copied or transmitted.
  </p>
  <p>
    6.6 Irrespective of the existence of copyright, you acknowledge that Agrichecker is the proprietor of all material
    on the website (except where a third party is indicated as the proprietor), whether it constitutes confidential
    information or not, and that you have no right, title or interest in any such material.
  </p>
  <p>
    6.7 Agrichecker authorises you only to view, copy, temporarily download to a local drive and to print the content of
    this website, or any part thereof, provided that such content is used for personal purposes and for information
    purposes only, and such content is used for non-commercial purposes.
  </p>
  <p>7. Listing of products or services</p>
  <p>7.1 Third party providers must ensure that all information listed on this website:-</p>
  <p>7.1.1 is accurate and not false, misleading, deceptive or fraudulent;</p>
  <p>7.1.2 does not breach any intellectual property rights of a third party;</p>
  <p>7.1.3 is made in compliance with all applicable laws, government regulations or guidelines;</p>
  <p>7.1.4 is not forged, threatening or offensive or otherwise constitutes harassment;</p>
  <p>
    7.1.5 does not contain confidential information or trade secrets of a third party unless the third party provider
    has obtained the consent of such third party;
  </p>
  <p>
    7.1.6 will not defame (libel or slander) another person or transmit misleading or inaccurate information of any
    kind, whether of a personal or commercial nature; and
  </p>
  <p>7.1.7 does not contain child pornography, bestiality or any other obscene or offensive material.</p>
  <p>7.2 Third party service providers warrant:-</p>
  <p>7.2.1 that the goods that they offer for sale and sell on this website:-</p>
  <p>
    7.2.1.1 are reasonably fit for the purpose or purposes for which the goods of that kind are commonly bought as it is
    reasonable to expect having regard to matters, without limitation, the description of those goods and their price;
  </p>
  <p>7.2.1.2 correspond with the description that the third party provider has posted on the website;</p>
  <p>7.2.1.3 if sold in bulk, will correspond with any samples;</p>
  <p>7.2.1.4 are not defective;</p>
  <p>
    7.2.1.5 are free of any charge or encumbrance such that the buyer will be able to enjoy full possession of those
    goods;
  </p>
  <p>7.2.1.6 will be offered for sale, and sold, in compliance with the laws of the Republic of South Africa; and</p>
  <p>
    7.2.1.7 are legally able to be offered for sale, and sold on this website, and otherwise, that the offer and sale of
    those goods is not in contravention of any law;
  </p>
  <p>7.2.2 that the services that they offer for sale and sell on this website:-</p>
  <p>7.2.2.1 will be rendered with due care and skill;</p>
  <p>
    7.2.2.2 any material supplied in connection with any services will be reasonably fit for the purpose for which they
    have been supplied;
  </p>
  <p>
    7.2.2.3 if the buyer has made it known to the third party provider the particular purpose for which the services are
    required or the
  </p>
  <p>
    result that the buyer wishes any service to achieve, then those services and any materials supplied in connection
    with those services will be reasonably fit for that purpose or are of such nature and quality that they may
    reasonably be expected to achieve that result; and
  </p>
  <p>
    7.2.2.4 are legally able to be offered for sale, and sold on this website, and otherwise, that the offer and sale of
    those services is not in contravention of any law.
  </p>
  <p>
    7.3 Third party providers are solely responsible for all material that they submit and any consequences that may
    result from their submission.
  </p>
  <p>
    7.4 All listings must be submitted to Agrichecker in the format and detail prescribed by Agrichecker from time to
    time.
  </p>
  <p>
    7.5 Following such submission, Agrichecker will review the listing to determine whether it is eligible for inclusion
    on the website within a period of 5 (five) business days.
  </p>
  <p>7.6 Agrichecker has the right, but not the obligation, without notice:-</p>
  <p>7.6.1 to monitor and moderate all materials submitted by third party providers; and</p>
  <p>
    7.6.2 to refuse or to restrict any third party provider&rsquo;s use of the listing service, temporarily or
    permanently.
  </p>
  <p>
    7.7 Third party service providers agree to pay all fees and other amounts charged by Agrichecker for the use and
    listing services from time to time. The fees list of Agrichecker is available to third party providers on request.
  </p>
  <p>8. Risk, limitation of liability and indemnity</p>
  <p>
    8.1 Your use of this website and the information contained on the website is entirely at your own risk and you
    assume full responsibility and risk of loss resulting from the use thereof.
  </p>
  <p>
    8.2 The transmission of information via the internet, including without limitation electronic mail, is susceptible
    to monitoring and interception. You bear all risk of transmitting information in this manner. Under no circumstances
    shall Agrichecker be liable for any loss, harm, or damage suffered by you as a result thereof. Agrichecker reserves
    the right to request independent verification of any information transmitted via electronic mail and you consent to
    such verification should Agrichecker deem it necessary.
  </p>
  <p>8.3 To the extent permissible by law:-</p>
  <p>
    8.3.1 Neither Agrichecker, its affiliates, shareholders, agents, consultants or employees shall be liable for any
    damages whatsoever, including without limitation any direct, indirect, special, incidental, consequential or
    punitive damages, howsoever arising (whether in an action arising out of contract, statute, delict or otherwise)
    related to the use of, or the ability to access or use the content of the website or any functionality
  </p>
  <p>
    thereof, or the information contained on the website, or of any linked website, even if Agrichecker knows or should
    reasonably have known or is expressly advised thereof.
  </p>
  <p>
    8.3.2 The liability of Agrichecker for faulty execution of the website as well as all damages suffered by you,
    whether direct or indirect, as a result of the malfunctioning of the website shall be limited to Agrichecker
    rectifying the malfunction within a reasonable time and free of charge, provided that Agrichecker is notified
    immediately of the damage or faulty execution of the website. This liability shall fall away and be expressly
    excluded if you attempt to correct or allow third parties to correct or attempt to correct the website without the
    prior written approval of Agrichecker. However, in no event shall Agrichecker be liable to you for loss of profits
    or special, incidental, consequential or punitive losses or damages arising out of or in connection with the website
    or its use or the delivery, installation, serving, performance or use of it in combination with other computer
    software.
  </p>
  <p>
    8.3.3 You hereby unconditionally and irrevocably indemnify Agrichecker and agree to hold Agrichecker free from all
    loss, damages, claims and/or costs, of whatsoever nature suffered or incurred by Agrichecker or instituted against
    Agrichecker as a direct or indirect result of:-
  </p>
  <p>8.3.3.1 your use of the website;</p>
  <p>8.3.3.2 your listing of products or services on the website;</p>
  <p>
    8.3.3.3 software, programs and support services supplied by, obtained by or modified by you or any third party
    without the consent or knowledge of Agrichecker;
  </p>
  <p>
    8.3.3.4 your failure to comply with any of the terms or any requirements which Agrichecker may impose from time to
    time;
  </p>
  <p>
    8.3.3.5 the actions or requirements of any telecommunications authority or a supplier of telecommunications services
    or software; or
  </p>
  <p>8.3.3.6 any unavailability of, or interruption in, the service which is beyond the control of Agrichecker.</p>
  <p>
    8.4 Agrichecker makes no warranty or representation as to the availability, accuracy or completeness of the content
    of the website. You expressly waive and renounce all your rights of whatsoever nature you may have against
    Agrichecker for any loss suffered by you, as a result of information supplied by Agrichecker being incorrect,
    incomplete or inaccurate.
  </p>
  <p>9. Confidentiality</p>
  <p>
    9.1 You shall hold in the strictest confidence and not disclose to any third party information acquired in
    connection with any aspect of the services offered by
  </p>
  <p>
    Agrichecker. You shall notify Agrichecker should you discover any loss or unauthorized disclosure of the
    information.
  </p>
  <p>
    9.2 Any information or material sent to Agrichecker will be deemed not to be confidential, unless otherwise agreed
    in writing by you and Agrichecker.
  </p>
  <p>10. Compliance with section 43(1) of ECTA</p>
  <p>In compliance with section 43(1) of ECTA, the following is noted:-</p>
  <p>10.1 Full name: Agrichecker (Pty) Ltd</p>
  <p>10.2 Registration number: 2017/108629/07</p>
  <p>
    10.3 Physical address: 10 Du Plessis Street, Humansdorp 10.4 Telephone numbers: 083 662 1634 / 082 448 3229<br />10.5
    Website address: http://agrichecker.com
  </p>
  <p>10.6 Electronic mail address: info@agrichecker.com</p>
  <p>10.7 Names of office bearers: Reynier Meyer and Elmarie Meyer<br />11. Compliance with laws</p>
  <p>
    You shall comply with all applicable laws, statutes, ordinances and regulations pertaining to your use of and access
    to this website.
  </p>
  <p>12. General clauses</p>
  <p>12.1 These Terms shall be governed in all respects by the laws of the Republic of South Africa.</p>
  <p>
    12.2 This website is controlled, operated and administered by Agrichecker from its offices within the Republic of
    South Africa. Agrichecker makes no representations that the content of the website is appropriate or available for
    use outside of South Africa. Access to the website from territories or countries where the content of the website is
    illegal is prohibited. Users may not use this website in violation of South African export laws and regulations. If
    the user accesses this website from locations outside of South Africa, that user is responsible for compliance with
    all local laws.
  </p>
  <p>
    12.3 Agrichecker does not guarantee continuous, uninterrupted or secure access to our services, as the operation of
    our website may be interfered with as a result of a number of factors which are outside of our control.
  </p>
  <p>
    12.4 If any provision of these Terms is held to be illegal, invalid or unenforceable for any reason, such provision
    shall be struck out from these Terms and the remaining provisions shall be enforced to the full extent of the law.
  </p>
  <p>
    12.5 Agrichecker&rsquo;s failure to act with respect to a breach by you or others does not constitute a waiver of
    our right to act with respect to subsequent similar breaches.
  </p>
  <p>
    12.6 You shall not be entitled to cede your rights or delegate your obligations in terms of these Terms to any third
    party without the prior written consent of Agrichecker.
  </p>
  <p>
    12.7 No party shall be bound by any expressed or implied term, representation, warranty, promise or the like not
    recorded herein, whether it induced the agreement and/or whether it was negligent or not.
  </p>
  <p>
    12.8 The headnotes to the paragraphs to these Terms are inserted for reference purposes only and shall not affect
    the interpretation of any of the provisions to which they relate.
  </p>
  <p>
    12.9 Words importing the singular shall include the plural and vice versa, words importing the masculine gender
    shall include females and words importing persons shall include partnerships and corporate and unincorporated
    entities.
  </p>
  <p>
    12.10 These Terms set forth the entire understanding and agreement between Agrichecker and you with respect to the
    subject matter hereof.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger ac-btn-danger" (click)="decline()">Decline</button>
  <button type="button" class="btn ac-btn-primary" (click)="accept()">Accept</button>
</div>
