import { ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { AddressProfile, DeliveryAddressProfile, BillingProfile } from 'core';

export const addressValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const address: AddressProfile = control.value;
  const invalid =
    (address.address ? !address.address.city : true) ||
    (address.address ? !address.address.postal_code : true) ||
    (address.address ? !address.address.province : true) ||
    (address.address ? !address.address.address_1 : true);
  return invalid
    ? {
        address_1: address.address ? !address.address.address_1 : true,
        city: address.address ? !address.address.city : false,
        postal_code: address.address ? !address.address.postal_code : true,
        province: address.address ? !address.address.province : true
      }
    : null;
};

export const deliveryAddressValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const res = addressValidator(control);
  const delivery_address: DeliveryAddressProfile = control.value;

  const valid_phone_number = delivery_address.tel
    ? delivery_address.tel.match(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    : true;

  const invalid =
    !!res ||
    !delivery_address.tel ||
    !delivery_address.geo_location ||
    !delivery_address.first_name ||
    !delivery_address.last_name ||
    !delivery_address.address.municipality ||
    !valid_phone_number;

  return invalid
    ? Object.assign(res ? res : {}, {
        tel: !delivery_address.tel,
        geo_location: !delivery_address.geo_location,
        first_name: !delivery_address.first_name,
        last_name: !delivery_address.last_name,
        invalid_tel: !valid_phone_number,
        municipality: delivery_address.address ? !delivery_address.address.municipality : true
      })
    : null;
};

export const billingAddressValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const res = addressValidator(control);
  const billing_address: BillingProfile = control.value;
  const invalid = !!res || !billing_address.first_name || !billing_address.last_name;

  return invalid
    ? Object.assign(res ? res : {}, {
        first_name: !billing_address.first_name,
        last_name: !billing_address.last_name
      })
    : null;
};
