<div class="container">
  <div class="row mb-5">
    <div class="col-md">
      <div class="row mb-3">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <app-categories
            [selectedCategory]="selectedCategory"
            (selectionChange)="selectCategory($event)"
          ></app-categories>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="col">
            <form [formGroup]="filter_form">
              <div class="form-row">
                <label attr.for="province">Province:</label>
                <select id="province" class="form-control mb-2" formControlName="province">
                  <option *ngFor="let prov of provinces" [value]="prov">{{ prov }}</option>
                </select>
              </div>
              <div *ngIf="municipalities?.length > 1" class="form-row">
                <label attr.for="province">Municipality:</label>
                <select id="munic" class="form-control mb-2" formControlName="municipality">
                  <option *ngFor="let munic of municipalities" [value]="munic">{{ munic }}</option>
                </select>
              </div>
              <div class="form-row">
                <label attr.for="province">Product Type:</label>
                <select id="type" class="form-control mb-2" formControlName="type">
                  <option *ngFor="let type of product_types" [value]="type">{{ type }}</option>
                </select>
              </div>
              <div *ngIf="filter_form.get('type').value !== 'Quote'" class="form-row custom-slider">
                <label attr.for="price">Price:</label>
                <ng5-slider
                  *ngIf="price"
                  id="price"
                  [value]="price?.value"
                  [highValue]="price?.highvalue"
                  [options]="price?.options"
                  formControlName="price"
                ></ng5-slider>
              </div>

              <div formArrayName="array" *ngFor="let control of controls; let i = index" class="form-row">
                <app-dynamic-control
                  class="col"
                  [control]="control"
                  [form]="filter_form"
                  [formControlName]="i"
                ></app-dynamic-control>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-9">
          <div *ngIf="alert?.showAlert">
            <ngb-alert [type]="alert?.type" [dismissible]="false">{{ alert.message }}</ngb-alert>
          </div>
          <app-product-list
            [products]="products"
            [currentPage]="currentPage"
            [pageLimit]="pageLimit"
            [productCount]="productCount"
            (pageChange)="updatePage($event)"
          ></app-product-list>
        </div>
      </div>
    </div>
  </div>
</div>
