// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  api_url: 'https://api.dev.agrichecker.com',
  blog_url: 'http://blog.agrichecker.com/blog',
  api_path: '/wp-json',
  merchant_url: 'https://merchant.dev.agrichecker.com',
  about_us_url: 'http://blog.agrichecker.com/',
  cdn: {
    url: 'https://cdn.agrichecker.com',
    bucket: 'cdn.dev.agrichecker.com'
  },
  agrichecker_info: {
    lng: 24.7676039,
    lat: -34.0272862,
    email: 'info@agrichecker.com',
    address: '10 du Plessis Street, Humansdorp, 6300'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
