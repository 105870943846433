<div class="mb-5">
  <div *ngIf="ad">
    <a *ngIf="ad.url.external" [href]="ad.url.url">
      <picture>
        <source media="(min-width: 768px)" [srcset]="ad.img('large')" />
        <source media="(min-width: 320px)" [srcset]="ad.img('small')" />
        <img [src]="ad.img('large')" />
      </picture>
    </a>

    <a *ngIf="!ad.url.external" class="cursor-pointer" (click)="goToUrl()">
      <picture>
        <source media="(min-width: 768px)" [srcset]="ad.img('large')" />
        <source media="(min-width: 320px)" [srcset]="ad.img('small')" />
        <img [src]="ad.img('large')" />
      </picture>
    </a>
  </div>
</div>
