<div class="container">
  <h3 class="headings">CONTACT US</h3>
  <div class="row">
    <div class="col-md-6 col-12">
      <form #form="ngForm" class="mb-5" (ngSubmit)="send()">
        <div class="col-12">
          <div class="form-group">
            <div class="row">
              <label>Name:</label>
            </div>
            <div class="row">
              <input name="name" class="form-control" [(ngModel)]="model.name" required #name="ngModel" />
              <div *ngIf="!(name.valid || form.pristine)">
                <p class="text-danger">This field is required</p>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label>Email:</label>
            </div>
            <div class="row">
              <input
                name="email"
                [email]="model.email"
                class="form-control"
                [(ngModel)]="model.email"
                required
                #email="ngModel"
              />
              <div *ngIf="!(email.valid || form.pristine)">
                <p class="text-danger">This field is required</p>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label>Message:</label>
            </div>
            <div class="row">
              <textarea
                name="message"
                rows="10"
                class="form-control"
                [(ngModel)]="model.message"
                #message="ngModel"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ngb-alert
              [type]="alert?.type"
              *ngIf="alert?.showAlert"
              (close)="alert.showAlert = false"
              [dismissible]="true"
              >{{ alert?.message }}</ngb-alert
            >
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <button [disabled]="!form.form.valid" class="btn ac-btn-primary mt-2 btn-block" type="submit">
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 col-12">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label><strong>Email:&nbsp;</strong></label>
            </div>
            <div class="col-8">
              {{ AGRICHECKER_INFO.email }}
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-4">
              <label><strong>Address:&nbsp;</strong></label>
            </div>
            <div class="col-8">
              {{ AGRICHECKER_INFO.address }}
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <agm-map [latitude]="AGRICHECKER_INFO.lat" [longitude]="AGRICHECKER_INFO.lng" [zoom]="16">
            <agm-marker [latitude]="AGRICHECKER_INFO.lat" [longitude]="AGRICHECKER_INFO.lng"> </agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </div>
</div>
