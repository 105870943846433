import { Injectable } from '@angular/core';
import { ApiService, Endpoints, Namespace } from 'core';

export interface ContactFormInfo {
  name: string;
  email: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
  constructor(private api: ApiService) {}

  send(contact: ContactFormInfo) {
    return this.api.post(Namespace.ac, Endpoints.contact_us, contact);
  }
}
