import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbAlertModule, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AccountModule } from './account/account.module';
import { AdvertiseComponent } from './advertise/advertise.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './authorization/auth.httpInterceptor';
import { CheckoutModule } from './checkout/checkout.module';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './header/header.component';
import { HomepageModule } from './homepage/homepage.module';
import { NotFoundComponent } from './notfound/notfound.component';
import { OrdersModule } from './orders/orders.module';
import { ProductsModule } from './products/products.module';
import { RegistrationModule } from './registration/registration.module';
import { ResetComponent } from './reset/reset.component';
import { SignInComponent } from './signin/signin.component';
import { LoadSpinnerInterceptor } from './utils/load-spinner-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContactComponent,
    AdvertiseComponent,
    NotFoundComponent,
    ResetComponent,
    SignInComponent
  ],
  imports: [
    FormsModule,
    CoreModule,
    NgbModalModule,
    NgbAlertModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-1Ii54pEm-3N84LeNYnqWtoRd0THpOPw',
      libraries: ['places']
    }),
    HomepageModule,
    OrdersModule,
    AccountModule,
    ProductsModule,
    RegistrationModule,
    CheckoutModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgbAlertModule,
    AppRoutingModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadSpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'api_url',
      useValue: environment.api_url + environment.api_path
    },
    {
      provide: 'cdn_config',
      useValue: {
        bucket: environment.cdn.bucket,
        url: environment.cdn.url
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
