import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './homepage/homepage.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { AdComponent } from './ad/ad.component';
import { RouterModule } from '@angular/router';
import { ProductsModule } from '../products/products.module';

@NgModule({
  declarations: [HomepageComponent, SlideshowComponent, AdComponent],
  imports: [NgbModule, CommonModule, ProductsModule, RouterModule]
})
export class HomepageModule {}
