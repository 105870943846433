import { ProductType, Range, PaginationParams } from 'core';

export interface StaticFilterParams {
  category?: string;
  province?: string;
  municipality?: string;
  price?: Range;
  type?: ProductType;
}
export interface DynamicFilterParams {
  [prop: string]: string | Range | boolean;
}

export interface SearchParams {
  search_string?: string;
}

export interface ProductFilterParams extends StaticFilterParams, DynamicFilterParams, PaginationParams, SearchParams {}

export enum SearchParamsKeys {
  category = 'category',
  search_string = 'search_string'
}

export enum DefaultFilterFields {
  all_provinces = 'All Provinces',
  any_filter = 'Any'
}
