import { Injectable } from '@angular/core';
import { ApiService, CollectionLocation, Namespace } from 'core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(private api: ApiService) {}

  public getCollectionLocationById(id: string): Observable<CollectionLocation> {
    return this.api.get(Namespace.ac, '/merchant/locations/' + id);
  }
}
