import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutComponent } from './checkout/checkout.component';
import { CartComponent } from './cart/cart.component';
import { AuthGuard } from '../authorization/auth.guard';
import { PaymentUnsuccessfulComponent } from './payment-unsuccessful/payment-unsuccessful.component';

const routes: Routes = [
  { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
  { path: 'cart', component: CartComponent },
  { path: 'payment-cancelled', component: PaymentUnsuccessfulComponent },
  { path: 'payment-error', component: PaymentUnsuccessfulComponent }
];

/**
 * Routing rules:
 * - should only go to checkout page from cart (logged in)
 * - should only go from
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule {}
