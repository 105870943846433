export enum ControlType {
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  range = 'range'
}

export class DynamicControl<T> {
  value: T;
  label?: string;
  caption?: string;
  order: number;
  control_type: ControlType;
  options: any;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      order?: number;
      control_type?: ControlType;
      options?: any;
    } = {}
  ) {
    this.value = options.value;
    this.label = options.label || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.control_type = options.control_type || ('' as ControlType);
    this.options = options['options'];
  }
}
