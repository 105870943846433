import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { IProduct } from 'core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  @Input() products: IProduct[];
  @Input() productCount: number;
  @Input() currentPage: number;
  @Input() pageLimit: number;

  @Output() pageChange = new EventEmitter<number>();

  private _innerWidth: number;

  constructor(private _scrollToService: ScrollToService) {}

  ngOnInit() {
    this._innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._innerWidth = event.target.innerWidth;
  }

  changePage(pageNumber: number) {
    this._scrollToService.scrollTo({ target: 'topPaginateController' });
    this.pageChange.emit(pageNumber);
  }

  get showDirectionLinks() {
    return this._innerWidth > 576;
  }

  get numberOfPageLinks() {
    return this._innerWidth < 360 ? 5 : 7;
  }
}
