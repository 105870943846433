<div class="container">
  <form *ngIf="token; else error" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div class="row justify-content-center my-5">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label class="headings">Email:</label>
          <input class="form-control" [value]="email" readonly />
        </div>
        <div class="form-group">
          <label class="headings">New Password:</label>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            placeholder="e.g. xxxxxxxxx"
            minlength="6"
            required
          />
          <div *ngIf="resetForm.invalid && (resetForm.dirty || resetForm.touched)" class="text-danger">
            <div *ngIf="resetForm.get('password').hasError('required')">
              This field is required
            </div>
            <div *ngIf="resetForm.get('password').hasError('minlength')">
              Your password needs to be at least 6 characters long
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="headings">Confirm New Password:</label>
          <input type="password" class="form-control" formControlName="confirmPassword" placeholder="e.g. xxxxxxxxx" />
          <div *ngIf="resetForm.invalid && (resetForm.dirty || resetForm.touched)" class="text-danger">
            <div *ngIf="resetForm.get('confirmPassword').invalid">
              This field is required
            </div>
            <div *ngIf="resetForm.invalid && !resetForm.get('confirmPassword').invalid">
              Your password doesn't match
            </div>
          </div>
        </div>
        <div class="form-group text-center">
          <button type="submit" button [disabled]="resetForm.invalid" class="btn ac-btn-primary col-md-4 col-6">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
  <ng-template #error>
    <div class="container">
      <div class="row justify-content-center" style="margin-bottom: 15%; margin-top: 15%;">
        <h5>
          The password reset link is invalid, it has either been used or has expired. Please
          <a class="ac-breadcrumb-links" routerLink="/signin">try again</a>.
        </h5>
      </div>
    </div>
  </ng-template>
</div>
