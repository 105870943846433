<div>
  <select class="form-control my-2" [formControl]="selectedLocationControl">
    <option disabled selected value=""> -- Choose collection location -- </option>
    <option *ngFor="let loc of locations; let i = index" [value]="i">{{ loc.name }} - {{ loc.province }}</option>
  </select>

  <div *ngIf="error_msg" class="my-5">
    <ngb-alert type="danger" [dismissible]="false">{{ error_msg }}</ngb-alert>
  </div>

  <div *ngIf="selected_location">
    <div class="row mt-2 pl-3">
      <div class="col-3">
        <label>Name: </label>
      </div>
      <div class="col-9">
        <strong>{{ selected_location?.name }}</strong>
      </div>
    </div>
    <div class="row pl-3">
      <div class="col-3">
        <label>Province: </label>
      </div>
      <div class="col-9">
        <strong>{{ selected_location?.address?.province }}</strong>
      </div>
    </div>
    <div class="col">
      <agm-map
        [latitude]="selected_location?.geo_location?.latitude"
        [longitude]="selected_location?.geo_location?.longitude"
        [zoom]="16"
      >
        <agm-marker
          [latitude]="selected_location?.geo_location?.latitude"
          [longitude]="selected_location?.geo_location?.longitude"
        >
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
