<div [formGroup]="form" class="form-group">
  <div formArrayName="array" [ngSwitch]="control?.control_type" class="mb-2">
    <label *ngIf="control?.control_type !== 'checkbox'" [attr.for]="formControlName">{{ control?.label }}:</label>
    <select [id]="formControlName" class="form-control" *ngSwitchCase="'dropdown'" [formControlName]="formControlName">
      <option *ngFor="let opt of control?.options" [value]="opt">{{ opt }}</option>
    </select>

    <div *ngSwitchCase="'checkbox'">
      <div class="form-check">
        <input
          class="mr-2 float-right"
          [id]="formControlName"
          [formControlName]="formControlName"
          type="checkbox"
          class="form-check-input"
        />
        <label class="form-check-label" [for]="formControlName">{{ control?.label }}</label>
      </div>
    </div>

    <div *ngSwitchCase="'range'" class="custom-slider">
      <ng5-slider
        [(value)]="(control?.value)[0]"
        [(highValue)]="(control?.value)[1]"
        [options]="control?.options"
        [formControlName]="formControlName"
      ></ng5-slider>
    </div>
  </div>
</div>
