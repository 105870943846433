import { Injectable } from '@angular/core';
import {
  ApiService,
  Endpoints,
  ImageService,
  ImageSize,
  IProduct,
  Namespace,
  Optional,
  ProductCategory,
  IProductsResult
} from 'core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProductFilterParams } from '../model/product';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private categories: ProductCategory[] = [];
  private categories$ = new BehaviorSubject(this.categories);
  private categories_fetched = false;
  private categories_fetching = false;
  private _productCache: Array<IProduct> = new Array();

  constructor(private api: ApiService, private imgService: ImageService) {}

  getCategories(): Observable<ProductCategory[]> {
    let obs = this.categories$;

    if (!this.categories_fetched && !this.categories_fetching) {
      this.categories_fetching = true;
      obs = this.api.get(Namespace.ac, Endpoints.categories).pipe(
        map((categories: Array<ProductCategory>) => {
          return [{ name: 'All Categories' } as ProductCategory, ...categories];
        }),
        tap((categories: Array<ProductCategory>) => {
          this.categories = categories;
          this.categories_fetched = true;
          this.categories_fetching = false;
          this.categories$.next(this.categories);
        })
      ) as BehaviorSubject<ProductCategory[]>;
    }
    return obs;
  }

  get topProducts() {
    return [
      { name: 'Bovine Semen', path: 'assets/img/Asset-1.svg', url: '/products?category=Bovine%20Semen' },
      { name: 'Fertilizer', path: 'assets/img/Asset-2.svg', url: '/products?category=Fertilizer' },
      { name: 'Hygiene', path: 'assets/img/Asset-3.svg', url: '/products?category=Hygiene' },
      { name: 'Livestock Feed', path: 'assets/img/Asset-4.svg', url: '/products?category=Livestock%20Feed' }
    ];
  }

  get comingSoon() {
    return [
      { name: 'Pesticides', path: 'assets/img/pest.svg' },
      { name: 'Seed', path: 'assets/img/seed.svg' }
    ];
  }

  getProductImage(id: string | number, size: ImageSize): string {
    const product = this._productCache.find(f => f.id === id);
    if (product) {
      return this.imgService.getImage(product.image, size);
    } else {
      this.getProductById(+id).subscribe(prod => {
        this._productCache.push(prod);
      });
      return '';
    }
  }

  retrieveProducts(searchParams: ProductFilterParams): Observable<IProductsResult> {
    return this.api.get(Namespace.ac, Endpoints.products, searchParams).pipe(
      map(({ results, ...rest }: IProductsResult) => {
        return {
          results: results.map(this.mapProduct),
          ...rest
        };
      }),
      tap((response: IProductsResult) => {
        this._productCache = response.results;
      })
    );
  }

  getProductsByIds(ids: Array<number>): Observable<IProduct[]> {
    const params = { ids: ids };
    return this.api
      .get(Namespace.ac, Endpoints.products, params)
      .pipe(map(prods => prods.map(p => this.mapProduct(p))));
  }

  getMaxProductPrice(): Observable<number> {
    return this.api.get(Namespace.ac, Endpoints.product_options).pipe(map((val: any) => val.max_price as number));
  }

  getProductById(id: number): Observable<IProduct> {
    const product = this._productCache.find(f => f.id === id);

    return product
      ? of(product)
      : this.api.get(Namespace.ac, Endpoints.products + '/' + id).pipe(
          map((p: IProduct) => {
            return this.mapProduct(p);
          })
        );
  }

  private mapProduct(input: any): IProduct {
    const ret: IProduct = input;
    ret.price = Optional.of(input.price);
    ret.delivery = Optional.of(input.delivery === '' ? null : input.delivery);
    ret.collection_locations = Optional.of(
      input.collection_locations === '' || input.collection_locations.length === 0 ? null : input.collection_locations
    );
    return ret;
  }
}
