<div class="container ">
  <form [formGroup]="loginForm" (ngSubmit)="signin()">
    <div class="row justify-content-center my-5">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label class="headings">Email:</label>
          <input class="form-control" type="email" formControlName="email" placeholder="Email" required />
          <div *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)" class="text-danger">
            <div *ngIf="!loginForm.get('email').value">
              Please enter a valid email address
            </div>
            <div *ngIf="loginForm.get('email').invalid && loginForm.get('email').value">
              Please enter a valid email address
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="headings">Password:</label>
          <input class="form-control" formControlName="password" required type="password" />
          <div *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)" class="text-danger">
            <div *ngIf="loginForm.get('password').invalid">
              This field is required
            </div>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="col">
            <div class="row">
              <button type="submit" [disabled]="loginForm.invalid" class="btn ac-btn-login col-md-4 col-6">
                SIGN IN
              </button>
              <div class="col-md-8 col-6">
                <a class="float-right" (click)="open(content)"><span class="ac-link">Forgot password?</span></a>
              </div>
            </div>
          </div>
          <ngb-alert
            *ngIf="alert?.showAlert"
            class="mt-2"
            (close)="alert.showAlert = !alert.showAlert"
            [type]="alert.type"
          >
            {{ alert.message }}
            <a *ngIf="is_merchant" [href]="MERCHANT_URL" target="_blank"><strong>here</strong></a>
          </ngb-alert>
          <div class="row mt-2">
            <div class="col">
              <a class=" ac-breadcrumb-links" routerLink="/register">
                Don't have an account? Start trading on Agrichecker.</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #content let-modal>
  <form [formGroup]="modalForm" (ngSubmit)="onModalSubmit()">
    <div class="modal-body">
      <p>
        Forgot your password? Please enter your email address. You will receive a link to create a new password via
        email.
      </p>
      <div class="form-group">
        <label>Email:</label>
        <input class="form-control" formControlName="email" required />
        <div *ngIf="modalForm.invalid && modalForm.dirty" class="text-danger">
          <div *ngIf="!modalForm.get('email').value">
            Please enter a valid email address
          </div>
          <div *ngIf="modalForm.get('email').invalid && modalForm.get('email').value">
            Please enter a valid email address
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" [disabled]="modalForm.invalid" class="btn ac-btn-primary">
          Reset Password
        </button>
      </div>
    </div>
  </form>
</ng-template>
