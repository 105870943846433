<div *ngIf="title" class="row d-flex align-items-start mb-2 pl-3">
  <h5>{{ title }}</h5>
</div>

<div *ngIf="for_delivery">
  <ngb-alert type="info" *ngIf="show" (close)="show = !show"
    >Drag the map marker to your exact delivery location</ngb-alert
  >
  <!-- <input acxGooglePlaces #searchInput2 #places2="acxGooglePlaces" class="form-control" (onSelect)="onAddressChange($event)" type="search" /> -->
  <agm-map [latitude]="lat" [longitude]="long" [zoom]="16" (mapReady)="onMapReady($event)">
    <div id="addressSearch" class="col-11 col-lg-8 input-group m-3">
      <input
        acxGooglePlaces
        #searchInput
        #places="acxGooglePlaces"
        class="form-control"
        (select)="onAddressChange($event)"
        type="search"
      />
      <div class="input-group-append">
        <button class="btn btn-primary " type="button" (click)="places.search()">
          <i *ngIf="!places.isLoading" class="fas fa-search"></i>
          <div *ngIf="places.isLoading" class="spinner-border spinner-border-sm"></div>
        </button>
      </div>
    </div>
    <agm-marker [latitude]="lat" [longitude]="long" [markerDraggable]="true" (dragEnd)="onMarkerDragEnd($event)">
    </agm-marker>
  </agm-map>
  <button
    class="btn my-3 pl-0"
    mat-button
    *ngIf="showPinLocationButton"
    color="primary"
    (click)="openGeocodeAddressResults()"
  >
    <mat-icon>edit_location</mat-icon>
    Update address from marker location
  </button>
</div>
<form class="mt-3" [formGroup]="addressForm">
  <div *ngIf="showForm">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>First Name: </label>
          <input type="text" class="form-control" formControlName="first_name" autocomplete="given-name" />
          <div *ngIf="hasError('first_name')" class="text-danger">
            {{ errors.REQUIRED }}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Last Name: </label>
          <input type="text" class="form-control" formControlName="last_name" autocomplete="family-name" />
          <div *ngIf="hasError('last_name')" class="text-danger">
            {{ errors.REQUIRED }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Business Name: </label>
      <input type="text" class="form-control" formControlName="company" autocomplete="organization" />
    </div>

    <div *ngIf="vat_no" class="form-group">
      <label>VAT No: </label>
      <input type="text" class="form-control" formControlName="vat_no" autocomplete="vat_no" />
    </div>

    <div class="form-group" formGroupName="address">
      <div class="form-group">
        <label>Address: </label>
        <input
          acxGooglePlaces
          (select)="onAddressChange($event)"
          type="search"
          class="form-control"
          formControlName="address_1"
          autocomplete="address-line1"
        />
        <div *ngIf="hasError('address.address_1')" class="text-danger">
          {{ errors.REQUIRED }}
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" formControlName="address_2" autocomplete="address-line2" />
      </div>
      <div class="form-group">
        <label>City / Town: </label>
        <input type="text" class="form-control" formControlName="city" autocomplete="address-line3" />
        <div *ngIf="hasError('address.city')" class="text-danger">
          {{ errors.REQUIRED }}
        </div>
      </div>
      <div class="form-group">
        <label>Postal Code: </label>
        <input type="text" class="form-control" formControlName="postal_code" autocomplete="postal-code" />
        <div *ngIf="hasError('address.postal_code')" class="text-danger">
          {{ errors.REQUIRED }}
        </div>
      </div>
      <div class="form-group">
        <label>Province: </label>
        <select class="form-control" formControlName="province">
          <option disabled selected [value]="null"> -- Select a Province -- </option>
          <option *ngFor="let prov of provinces">{{ prov }}</option>
        </select>
        <div *ngIf="hasError('address.province')" class="text-danger">
          {{ errors.REQUIRED }}
        </div>
      </div>
      <div class="form-group" *ngIf="for_delivery">
        <label>Municipality: </label>
        <select class="form-control" formControlName="municipality">
          <option disabled selected [value]="null"> -- {{ municipalityPlaceholder }} -- </option>
          <option *ngFor="let munic of munics">{{ munic }}</option>
        </select>
        <div *ngIf="hasError('address.municipality')" class="text-danger">
          {{ errors.REQUIRED }}
        </div>
      </div>
    </div>

    <div *ngIf="email" class="form-group">
      <div class="form-group">
        <label>Email: </label>
        <input type="text" class="form-control" formControlName="email" autocomplete="email" />
      </div>
    </div>

    <div *ngIf="phone" class="form-group">
      <div class="form-group">
        <label>Phone: </label>
        <input type="text" class="form-control" formControlName="tel" autocomplete="tel" />
        <div *ngIf="hasError('tel')" class="text-danger">
          {{ errors.REQUIRED }}
        </div>
        <div *ngIf="hasError('tel', 'invalid_tel')" class="text-danger">
          {{ errors.INVALID_TEL }}
        </div>
      </div>
    </div>
  </div>
</form>
