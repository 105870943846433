import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MerchantRegistrationComponent } from './merchant-registration/merchant-registration.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { RegistrationComponent } from './registration/registration.component';
import { TermsComponent } from './terms/terms.component';

@NgModule({
  declarations: [MerchantRegistrationComponent, CustomerRegistrationComponent, RegistrationComponent, TermsComponent],
  imports: [CommonModule, NgbModule, FormsModule, RegistrationRoutingModule, ReactiveFormsModule],
  entryComponents: [TermsComponent]
})
export class RegistrationModule {}
