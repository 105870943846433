<div *ngIf="products.length > 0; else default">
  <div
    id="topPaginateController"
    class="d-flex flex-row justify-content-center justify-content-sm-end p-sm-0"
    style="padding-right: 40px"
  >
    <pagination-controls
      [directionLinks]="showDirectionLinks"
      [maxSize]="numberOfPageLinks"
      *ngIf="productCount > pageLimit"
      (pageChange)="changePage($event)"
    ></pagination-controls>
  </div>
  <div
    *ngFor="
      let product of products
        | paginate
          : {
              itemsPerPage: pageLimit,
              currentPage: currentPage,
              totalItems: productCount
            }
    "
  >
    <app-product-summary [product]="product"></app-product-summary>
  </div>
  <div class="d-flex flex-row justify-content-center justify-content-sm-end p-sm-0" style="padding-right: 40px">
    <pagination-controls
      [directionLinks]="showDirectionLinks"
      [maxSize]="numberOfPageLinks"
      *ngIf="productCount > pageLimit"
      (pageChange)="changePage($event)"
    ></pagination-controls>
  </div>
</div>

<ng-template #default>
  <div class="row justify-content-center" style="margin-bottom: 15%; margin-top: 15%;">
    <label>No items match that criteria.</label>
  </div>
</ng-template>
