<div class="container ">
  <h3 class="headings mb-4">CHECKOUT</h3>
  <div class="row">
    <div class="col-12 col-md-6 mt-2">
      <!-- billing adress -->
      <app-address title="Billing Details" [formControl]="billing_address"></app-address>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <!-- Delivery / Collection Location -->
      <div *ngIf="mutliple_delivery_options" class="mb-3">
        <h5>Choose your method of delivery:</h5>
        <div *ngIf="can_deliver" class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="delivery"
            name="delivery_method"
            id="deliveryOption"
            [formControl]="delivery_method"
          />
          <label for="deliveryOption">Delivery</label>
        </div>
        <div *ngIf="can_collect" class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="collection"
            name="delivery_method"
            id="collectionOption"
            [formControl]="delivery_method"
          />
          <label for="collectionOption">Collection</label>
        </div>
      </div>

      <div *ngIf="delivered; else collection">
        <div class="row d-flex align-items-start mb-2 pl-3">
          <h5>Delivery Details</h5>
        </div>
        <div class="row mb-3 pl-3">
          <button (click)="useBillingDetails()" type="button" class="btn btn-link ac-link p-0">
            Use Billing Details
          </button>
          <button type="button" class="btn btn-link ac-link divider-before p-0" (click)="addNewDeliveryAddress()">
            Enter New Address
          </button>
          <button
            *ngIf="hasDeliveryHistory && showDeliveryForm"
            (click)="usePreviousAddress()"
            type="button"
            class="btn btn-link ac-link divider-before p-0"
          >
            Use Previous Address
          </button>
        </div>

        <div *ngIf="!showDeliveryForm" class="list-group mb-3">
          <button
            *ngFor="let info of deliveryHistory; let i = index"
            (click)="selectDeliveryAddress(i)"
            class="ac-list-group-item list-group-item list-group-item-action list-group-item"
            [ngClass]="{ active: i == selectedAddressIndex }"
          >
            <div class="row pl-2">
              <div class="circle-adornment m-1 mr-3"></div>
              <div class="col">
                <strong class="row">{{ info.first_name }} {{ info.last_name }}</strong>
                <span *ngIf="info.company && info.company !== ''" class="row">{{ info.company }}</span>
                <span class="row">{{ formatAddress(info.address) }}</span>
                <span class="row">{{ info.tel }}</span>
              </div>
              <button (click)="editDeliveryAddress(i)" class="align-self-center btn btn-link ac-link pr-2 active">
                Update
              </button>
            </div>
          </button>
        </div>

        <app-address [showForm]="showDeliveryForm" [for_delivery]="true" [formControl]="delivery_address"></app-address>
      </div>

      <ng-template #collection>
        <div>
          <h5 class="mb-4 ">Collection location</h5>
          <div>
            <app-collection-locations
              [locations]="collection_locations"
              [location]="selected_location"
              (locationChanged)="update_collection_location($event)"
            ></app-collection-locations>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="row mb-2" *ngIf="quote_products?.length > 0">
    <div class="col">
      <app-quote-products [merchant]="merchant" [products]="quote_products"></app-quote-products>
    </div>
  </div>
  <ngb-alert class="mt-2" type="danger" *ngIf="delivered && !will_deliver" [dismissible]="false"
    >Unfortunately, delivery is not yet available to your delivery address. Please check the available delivery
    locations.</ngb-alert
  >
  <div *ngIf="has_shop_products">
    <div class="row">
      <div class="col">
        <!-- order details -->
        <acx-order-details [order]="order" [deliveryMethod]="delivery_method.value"></acx-order-details>
      </div>
    </div>
  </div>

  <div class="card mb-5">
    <div class="list-group-item" *ngIf="has_shop_products">
      <div class="form-check">
        <input class="form-check-input" type="radio" checked />
        <label>Ozow</label>
      </div>
      <ngb-alert type="info" [dismissible]="false"
        >Pay with Ozow real-time EFT. (Your order status will be updated immediately after successful
        payment).</ngb-alert
      >
      <!-- ipay (ozow) payment method -->
    </div>
    <div class="list-group-item">
      <div class="row">
        <div class="col-md-8">
          <div class="form-check">
            <!-- terms and conditions -->
            <input class="form-check-input" type="checkbox" [formControl]="ac_terms" /><label
              >I've read and accept the <strong>Agrichecker</strong>
              <span class="ac-link" (click)="openTerms()"> terms & conditions</span></label
            >
          </div>
          <div class="form-check">
            <!-- terms and conditions -->
            <input class="form-check-input" type="checkbox" [formControl]="merchant_terms" /><label
              >I've read and accept the <strong>{{ merchant?.name }}</strong>
              <span class="ac-link" (click)="openMerchantTerms(content)"> terms & conditions</span></label
            >
          </div>
          <div class="text-danger">
            <div *ngIf="!ac_terms.value || !merchant_terms.value">
              {{ errors.UNACCEPTED_TERMS }}
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button class="btn ac-btn-secondary btn-block" (click)="back()">Back to Cart</button>
        </div>
        <div class="col-md-2">
          <button class="btn ac-btn-primary btn-block float-right" (click)="placeOrder()" [disabled]="invalid">
            place order
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ merchant.name }} Terms and Conditions</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ tsandcs }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger ac-btn-danger" (click)="decline()">Decline</button>
    <button type="button" class="btn ac-btn-primary" (click)="accept()">Accept</button>
  </div>
</ng-template>
