import { isNumberFieldType, ProductCategory, ProductType, Range } from 'core';
import { DefaultFilterFields, ProductFilterParams } from '../model/product';
import { ControlType, DynamicControl } from './dynamic-control';

export class CategoryMapper {
  constructor() {}

  buildFormArray(category: ProductCategory): DynamicControl<boolean | number | Range>[] {
    if (category && category.fields && category.fields.length > 0) {
      return category.fields
        .filter(field => this.categoryFieldTypes(field))
        .map(field => {
          let options;
          if (Array.isArray(field.type) && field.type[1] !== 'number') {
            options = {
              control_type: ControlType.dropdown,
              label: field.name,
              options: field.type
            };
          } else if (isNumberFieldType(field.type)) {
            options = {
              control_type: ControlType.range,
              label: field.name,
              options: { floor: field.type.number.min, ceil: field.type.number.max },
              value: [field.type.number.min, field.type.number.max] as Range
            };
            return new DynamicControl<Range>(options);
          } else if (field.type === 'boolean') {
            options = {
              control_type: ControlType.checkbox,
              label: field.name
            };
          }
          if (options) {
            return new DynamicControl(options);
          }
        });
    }
    return [];
  }

  mutateCategory(cat: ProductCategory): ProductCategory {
    cat.fields = cat.fields.filter(field => this.categoryFieldTypes(field));
    cat.fields
      .filter(field => Array.isArray(field.type) && field.type[0] !== DefaultFilterFields.any_filter)
      .forEach(field => {
        (field.type as Array<string>).unshift(DefaultFilterFields.any_filter);
      });

    return cat;
  }

  mapToQueryParams(filter: any, cat: ProductCategory): ProductFilterParams {
    const params = {};

    if (cat && cat.name) {
      params['category'] = cat.name;
    }

    if (filter.type !== DefaultFilterFields.any_filter) {
      params['type'] = filter.type;
    }

    if (filter.type !== ProductType.quote) {
      params['price'] = filter.price;
    }

    if (filter.province !== DefaultFilterFields.all_provinces) {
      params['province'] = filter.province;
      if (filter.municipality !== DefaultFilterFields.any_filter) {
        params['municipality'] = filter.municipality;
      }
    }

    // category specific fields
    if (cat && cat.fields && cat.fields.length > 0) {
      cat.fields
        .filter(field => this.categoryFieldTypes(field))
        .forEach((field, index) => {
          // map fields in array to object keys
          const val = filter.array[index];
          if (val && val !== '' && val !== DefaultFilterFields.any_filter) {
            Object.assign(params, { [field.name]: val });
          }
        });
    }

    return params;
  }

  private categoryFieldTypes(field) {
    return isNumberFieldType(field.type) || field.type === 'boolean' || Array.isArray(field.type);
  }
}
