import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {
  private history = [];
  private _previousRoute$: ReplaySubject<string>;

  constructor(private router: Router) {
    this._previousRoute$ = new ReplaySubject();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history.push(urlAfterRedirects);
        this._previousRoute$.next(this.history[this.history.length - 1] || '/home');
      });
  }

  get previousRoute() {
    return this.history[this.history.length - 1] || '/home';
  }

  public getPreviousRoute() {
    return this._previousRoute$;
  }
}
