import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert, AlertType, ERROR, MerchantRegistrationRequest } from 'core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ValidatePasswords } from '../password.validator';
import { RegistrationService } from '../registration.service';
import { TermsComponent } from '../terms/terms.component';

@Component({
  selector: 'app-merchant-registration',
  templateUrl: './merchant-registration.component.html',
  styleUrls: ['./merchant-registration.component.scss']
})
export class MerchantRegistrationComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  readonly errors = ERROR;
  private readonly MERCHANT_URL = environment.merchant_url;

  alert: Alert;
  registerForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    passwords: this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required]
      },
      { validator: ValidatePasswords }
    ),
    tel: ['', [Validators.required, Validators.minLength(6)]],
    subscribe: [false],
    accepted: [false, Validators.requiredTrue]
  });

  submitted: boolean;

  constructor(private fb: FormBuilder, private modalService: NgbModal, private registration: RegistrationService) {}

  ngOnInit() {
    this.submitted = false;
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  save() {
    const req: MerchantRegistrationRequest = {
      email: this.registerForm.controls['email'].value,
      company: this.registerForm.controls['name'].value,
      tel: this.registerForm.controls['tel'].value,
      password: this.registerForm.controls['passwords'].value['password'],
      subscribe: this.registerForm.controls['subscribe'].value
    };

    this.sub = this.registration.registerMerchant(req).subscribe(
      _ => {
        location.href = this.MERCHANT_URL;
      },
      err => {
        if (err.error.code === 'user_exists') {
          this.alert = new Alert(AlertType.danger, ERROR.USER_EXISTS);
        } else {
          this.alert = new Alert(AlertType.danger, ERROR.REGISTRATION_ERROR);
        }
      }
    );
  }

  open() {
    const modalRef = this.modalService.open(TermsComponent);
    modalRef.result.then(
      res => {
        this.registerForm.controls['accepted'].setValue(!!res);
      },
      err => this.registerForm.controls['accepted'].setValue(false)
    );
  }
}
