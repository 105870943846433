<form *ngIf="!submitted" [formGroup]="registerForm" (ngSubmit)="save()">
  <div class="row justify-content-center">
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label class="headings">Company Name:</label>
        <input class="form-control col-12" formControlName="name" placeholder="e.g. AB" required />
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
          <div *ngIf="registerForm.get('name').hasError('required')">
            {{ errors.REQUIRED }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">Email Address:</label>
        <input class="form-control" name="email" formControlName="email" placeholder="e.g. name@domain.com" required />
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
          <div *ngIf="registerForm.get('email').hasError('required')">
            {{ errors.REQUIRED }}
          </div>
          <div *ngIf="registerForm.get('email').hasError('email')">
            {{ errors.INVALID_EMAIL }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">Phone Number:</label>
        <input class="form-control" formControlName="tel" placeholder="e.g. 0123456789" required minlength="6" />
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
          <div *ngIf="registerForm.get('tel').hasError('required')">
            {{ errors.REQUIRED }}
          </div>
          <div *ngIf="registerForm.get('tel').hasError('minlength')">
            {{ errors.INVALID_TEL }}
          </div>
        </div>
      </div>
      <div formGroupName="passwords">
        <div class="form-group">
          <label class="headings">Password:</label>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            minlength="6"
            placeholder="e.g. xxxxxxxxx"
            required
          />
          <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
            <div
              *ngIf="
                registerForm
                  .get('passwords')
                  .get('password')
                  .hasError('required')
              "
            >
              {{ errors.REQUIRED }}
            </div>
            <div
              *ngIf="
                registerForm
                  .get('passwords')
                  .get('password')
                  .hasError('minlength')
              "
            >
              {{ errors.SHORT_PASSWORD }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="headings">Confirm Password:</label>
          <input type="password" class="form-control" formControlName="confirmPassword" placeholder="e.g. xxxxxxxxx" />
          <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
            <div
              *ngIf="
                registerForm
                  .get('passwords')
                  .get('confirmPassword')
                  .hasError('required')
              "
            >
              {{ errors.REQUIRED }}
            </div>
            <div *ngIf="registerForm.get('passwords').hasError('non_matching_passwords')">
              {{ errors.NON_MATCHING_PASSWORDS }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">
          <input type="checkbox" formControlName="accepted" /> I've read and accepted the
          <a (click)="open()"><span class="ac-label ac-link">Terms And Conditions of Agrichecker</span></a></label
        >
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="row text-danger">
          <div *ngIf="registerForm.get('accepted').invalid" class="col-sm">
            {{ errors.UNACCEPTED_TERMS }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">
          <input formControlName="subscribe" type="checkbox" /> I would like to receive regular information and
          promotions from Agrichecker
        </label>
      </div>
      <div class="text-center">
        <ngb-alert *ngIf="alert?.showAlert" (close)="alert.showAlert = !alert.showAlert" [type]="alert.type">
          {{ alert.message }}</ngb-alert
        >
        <button type="submit" button [disabled]="registerForm.invalid" class="btn ac-btn-primary mt-2 col-md-4 col-6">
          CREATE ACCOUNT
        </button>
      </div>
    </div>
  </div>
</form>

<!-- <app-merchantprofile *ngIf="submitted"></app-merchantprofile> -->
