import { Injectable } from '@angular/core';
import { ApiService, Namespace, Endpoints } from 'core';
import { QuoteRequest } from '../model/quote';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  constructor(private api: ApiService) {}

  request(quote: QuoteRequest) {
    return this.api.post(Namespace.ac, Endpoints.request_quote, quote);
  }
}
