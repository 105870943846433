import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'core';
import { Ng5SliderModule } from 'ng5-slider';
import { DynamicControlComponent } from './dynamic-control/dynamic-control.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { CategoriesComponent } from './categories/categories.component';
import { ProductSummaryComponent } from './product-summary/product-summary.component';
import { ProductsRoutingModule } from './products-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

@NgModule({
  declarations: [
    ProductSearchComponent,
    ProductSummaryComponent,
    ProductDetailComponent,
    DynamicControlComponent,
    ProductListComponent,
    CategoriesComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ProductsRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgxPaginationModule,
    ScrollToModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-1Ii54pEm-3N84LeNYnqWtoRd0THpOPw',
      libraries: ['places']
    })
  ],
  exports: [CategoriesComponent]
})
export class ProductsModule {}
