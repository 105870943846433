import { Injectable } from '@angular/core';
import { ApiService, Context, CustomerProfile, MerchantProfile, Namespace, UserProfile, Endpoints } from 'core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './authorization/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private api: ApiService, private auth: AuthService) {}

  get profile(): UserProfile {
    return this.auth.profile;
  }

  getFullProfile(): Observable<CustomerProfile> {
    return this.api.get(Namespace.ac, Endpoints.customer_me).pipe(map(res => res as CustomerProfile));
  }

  updateFullProfile(profile: CustomerProfile) {
    return this.api.post(Namespace.ac, Endpoints.customer_me, profile);
  }

  public getMerchantById(id: number): Observable<MerchantProfile> {
    return this.api.get(Namespace.ac, Endpoints.merchant_profile + '/' + id);
  }
}
