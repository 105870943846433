import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert, AlertType, CustomerRegistrationRequest, ERROR } from 'core';
import { Subscription } from 'rxjs';
import { ValidatePasswords } from '../password.validator';
import { TermsComponent } from '../terms/terms.component';
import { RegistrationService } from '../registration.service';

@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss']
})
export class CustomerRegistrationComponent implements OnInit, OnDestroy {
  readonly errors = ERROR;

  private sub: Subscription;
  alert: Alert;

  registerForm = this.fb.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    passwords: this.fb.group(
      {
        password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
        confirmPassword: ['', Validators.required]
      },
      { validator: ValidatePasswords }
    ),
    subscribe: [false],
    accepted: [false, Validators.requiredTrue]
  });

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private registration: RegistrationService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  save() {
    const req: CustomerRegistrationRequest = {
      email: this.registerForm.controls['email'].value,
      first_name: this.registerForm.controls['name'].value,
      last_name: this.registerForm.controls['surname'].value,
      password: this.registerForm.controls['passwords'].value['password'],
      subscribe: this.registerForm.controls['subscribe'].value
    };

    this.sub = this.registration.registerCustomer(req).subscribe(
      _ => {
        this.router.navigate(['/signin']);
      },
      err => {
        if (err.error.code === 'user_exists') {
          this.alert = new Alert(AlertType.danger, ERROR.USER_EXISTS);
        } else {
          this.alert = new Alert(AlertType.danger, ERROR.REGISTRATION_ERROR);
        }
      }
    );
  }

  open() {
    const modalRef = this.modalService.open(TermsComponent);
    modalRef.result.then(
      res => {
        this.registerForm.controls['accepted'].setValue(!!res);
      },
      err => this.registerForm.controls['accepted'].setValue(false)
    );
  }
}
