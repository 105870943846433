<header class="mb-2">
  <nav class="navbar navbar-dark bg-dark mb-3">
    <div class="container">
      <div class="navbar-expand ml-auto navbar-nav">
        <ul class="navbar-nav ml-auto">
          <a *ngIf="!(isLoggedIn() | async); else loggedIn" class="loggedOut" routerLink="/signin" role="button">
            <i class="fas fa-user mx-2"></i>Sign In</a
          >
        </ul>
      </div>
    </div>
  </nav>
  <div class="container">
    <div class="d-flex flex-row justify-content-between flex-wrap align-items-end">
      <div class="mb-4 mr-4">
        <a routerLink="/home">
          <img src="assets/img/agrichecker.logo_.svg" class="ac-header-logo" />
        </a>
      </div>
      <div class="flex-grow-1">
        <div class="d-flex flex-row justify-content-end flex-wrap">
          <div class="flex-grow-1 mb-3">
            <div class="input-group">
              <input
                type="text"
                (keyup)="onKey($event)"
                [value]="search"
                class="form-control search-bar"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-button"
              />
              <div ngbDropdown>
                <button class="btn categories" id="categoriesDropDown" ngbDropdownToggle>
                  {{ selectedCategory || 'All Categories' }}
                </button>
                <div ngbDropdownMenu aria-labelledby="categoriesDropDown" class="categories-dropdown">
                  <button
                    (click)="selectedCategory = category"
                    *ngFor="let category of categories"
                    class="dropdown-item categories"
                  >
                    {{ category }}
                  </button>
                </div>
              </div>
              <div class="input-group-append">
                <button
                  (click)="onClick()"
                  class="btn btn-outline-secondary ac-btn-primary"
                  style="height: 93.4%;"
                  type="button"
                  id="search-button"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0 pl-4">
            <app-cart-btn></app-cart-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
</header>

<ng-template #loggedIn>
  <div ngbDropdown>
    <span class="loggedIn"
      ><a role="button" ngbDropdownToggle> <i class="fas fa-user mx-2"></i>{{ username }}</a></span
    >
    <div ngbDropdownMenu aria-labelledby="categoriesDropDown" class="categories-dropdown">
      <a routerLink="/account" class="dropdown-item">Account</a>
      <a (click)="logout()" class="dropdown-item ac-link">Logout</a>
    </div>
  </div>
</ng-template>
