import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-unsuccessful',
  templateUrl: './payment-unsuccessful.component.html',
  styleUrls: ['./payment-unsuccessful.component.scss']
})
export class PaymentUnsuccessfulComponent implements OnInit {
  reason: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.reason = url[0].path;
    });
  }

  get isError() {
    return this.reason === 'payment-error';
  }
}
