<div *ngIf="productExists; else noProduct" class="container mb-5">
  <div *ngIf="alert?.showAlert">
    <ngb-alert [type]="alert?.type" [dismissible]="false">{{ alert.message }}</ngb-alert>
  </div>
  <div class="row">
    <div class="col-md-6">
      <img *ngIf="product_image; else defaultImg" style="width:100%" [src]="product_image" />
    </div>
    <ng-template #defaultImg><i class="fas fa-box-open fa-10x ac-product-logo"></i></ng-template>
    <div class="col-md-6">
      <div class="row">
        <div class="col">
          <h5 class="headings ac-product-label">{{ name }}</h5>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div *ngIf="!isQuote; else quote">
            <label class="headings ac-price-label mr-1">{{ price | currency: 'ZAR':'symbol-narrow':'1.2-2' }} </label>
            <label> Excl. VAT</label>
          </div>
          <ng-template #quote>
            <label class="font-weight-bold">{{ price }}</label>
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label *ngIf="unitSize"> <strong>Unit Size:</strong> {{ unitSize }}{{ unit }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label *ngIf="is_shop">
            <strong>Stock: </strong>
            <span class="stock-label" [ngClass]="{ 'out-of-stock-label': out_of_stock }"> {{ stock }}</span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label *ngIf="batchSize"> <strong>Batch Size:</strong> {{ batchSize }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <label *ngIf="minQuantity"><strong>Min Qty :</strong> {{ minQuantity }}</label>
        </div>
        <div class="col-md">
          <label *ngIf="maxQuantity"><strong>Max Qty:</strong> {{ maxQuantity }}</label>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-5">
          <button *ngIf="button" class="btn ac-btn-buy btn-block bg-primary" (click)="addToCart()" [disabled]="!canAdd">
            <i class="fas mr-2" [ngClass]="button?.icon"></i>{{ button?.label }}
          </button>
        </div>
        <div (click)="disabledInfo()" *ngIf="!canAdd" class="ac-link pt-2 ml-3 ml-md-0">Why can't I order this?</div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row mt-3 mb-3">
    <div class="col-md-6">
      <h3 class="headings">Product Details</h3>
      <div class="row">
        <div class="col">
          <label *ngIf="category">
            <strong>Category:</strong><span class="ml-1">{{ category }}</span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label *ngIf="description"
            ><strong>Description:</strong><span class="ml-1">{{ description }}</span></label
          >
        </div>
      </div>
      <div *ngFor="let field of fields" class="row">
        <div class="col">
          <label>
            <strong>{{ field.name }}:</strong>
            <span *ngIf="isUrl(field)" class="ml-1">
              <a target="_blank" rel="noopener noreferrer" [href]="field.value">{{ field.value }}</a>
            </span>
            <span *ngIf="isFile(field)" class="ml-1">
              <a target="_blank" rel="noopener noreferrer" [href]="field.value"><i class="far fa-file-pdf ml-2"></i></a>
            </span>
            <span *ngIf="isOther(field)" class="ml-1">{{ field.value }}</span>

            <span *ngIf="isBoolean(field)" class="ml-1">{{ field.value === 'true' ? 'Yes' : 'No' }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h3 class="headings">Merchant Details</h3>
      <div *ngIf="loggedIn | async; else loggedOut">
        <div class="row">
          <div class="col">
            <label>{{ merchant.name }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label *ngIf="merchant && merchant.tel"><strong>Tel:</strong> </label
            ><label class="ml-1">{{ merchant.tel }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label *ngIf="merchant"
              ><strong>Email:</strong
              ><a [href]="'mailto:' + merchant.email" class="ml-1">{{ merchant.email }}</a></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div *ngIf="collection_locations || for_delivery" class="row mt-3">
    <div *ngIf="collection_locations" class="col-md-6">
      <div class="row">
        <div class="col-md">
          <h3 class="headings">Collection Details</h3>
          <div style="overflow-x:auto;">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>NAME:</th>
                  <th>PROVINCE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let loc of collection_locations"
                  (click)="loadLocation(loc.id)"
                  [ngClass]="{ 'table-active': loc.id == selectedLocation }"
                >
                  <td>{{ loc.name }}</td>
                  <td>{{ loc.province }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <div *ngIf="latitude && longitude">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="16">
          <agm-marker [latitude]="latitude" [longitude]="longitude"> </agm-marker>
        </agm-map>
      </div>
    </div>
    <div *ngIf="for_delivery" class="col-md-6">
      <h3 class="headings">Delivery Details</h3>
      <div style="overflow-x:auto">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>PROVINCE:</th>
              <th>MUNICIPALITY:</th>
              <th>RATE:</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rate of delivery_rates">
              <td>{{ rate.province }}</td>
              <td>{{ rate.municipality }}</td>
              <td>{{ rate.rate | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #loggedOut>
  <label> Please <a routerLink="/signin">sign in</a> to view merchant contact details.</label>
</ng-template>

<ng-template #noProduct>
  <div class="container">
    <div class="row justify-content-center" style="margin-bottom: 15%; margin-top: 15%;">
      <label>That product does not exist.</label>
    </div>
  </div>
</ng-template>
