import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
export interface Slide {
  image: string;
  url: string;
  external?: boolean;
}

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
  slides: Slide[];

  private readonly MERCHANT_URL = environment.merchant_url;
  private readonly BLOG_URL = environment.blog_url;
  private readonly ABOUT_URL = environment.about_us_url;

  constructor() {
    this.slides = [
      { image: 'assets/img/Blog-Fertilizer-PS.jpg', url: this.BLOG_URL, external: true },
      { image: 'assets/img/Buy-Sunflowers-PS.jpg', url: '/products' },
      {
        image: 'assets/img/Learn-More-Field-PS.jpg',
        url: this.ABOUT_URL,
        external: true
      },
      { image: 'assets/img/Sell-Bags-PS-1.jpg', url: this.MERCHANT_URL, external: true }
    ];
  }

  ngOnInit() {}
}
