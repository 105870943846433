import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert, AlertType, IProduct, ProductType, ImageSize } from 'core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../authorization/auth.service';
import { CartProduct } from '../../model/cart';
import { CartService } from '../cart.service';
import { first } from 'rxjs/operators';
import { ProductService } from '../../products/product.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  private isLoggedIn = false;
  private _valid = true;
  alert: Alert;
  private subAuth: Subscription;
  private subProds: Subscription;

  constructor(
    private router: Router,
    private cartService: CartService,
    private auth: AuthService,
    private productService: ProductService
  ) {}

  ngOnInit() {
    // get products
    this.subAuth = this.auth.isLoggedIn.subscribe(
      val => (this.isLoggedIn = typeof val === 'boolean' ? <boolean>val : false)
    );
    this.subProds = this.cartService.getCartProducts().subscribe(
      prods => {
        this.cleanseCartProducts(prods);
      },
      _ => {
        this.cartService.clear();
      }
    );
  }

  ngOnDestroy() {
    if (this.subAuth) this.subAuth.unsubscribe();
    if (this.subProds) this.subProds.unsubscribe();
  }

  cleanseCartProducts(prods: CartProduct[]) {
    // removes weird stuff from cart that should not be there
    prods
      .filter(prod => prod.product.product_type !== ProductType.shop && prod.product.product_type !== ProductType.quote)
      .forEach(prod => this.cartService.remove(prod.product.id));
  }

  checkout() {
    this.cartService
      .getCartProducts()
      .pipe(first())
      .subscribe(
        prods => {
          this.cleanseCartProducts(prods);

          if (this.valid) {
            // navigate to checkout page
            if (!this.isLoggedIn) {
              this.auth.redirectUrl = '/checkout';
            }
            this.router.navigate(['/checkout']);
          }
        },
        _ => {
          this.cartService.clear();
        }
      );
  }

  get shop_products(): Array<CartProduct> {
    return this.cartService.shop_products;
  }

  get quote_products(): Array<CartProduct> {
    return this.cartService.quote_products;
  }

  get hasQuotes() {
    return this.quote_products.length > 0;
  }

  get hasCheckouts() {
    return this.shop_products.length > 0;
  }

  get hasStock() {
    return this.shop_products.reduce((hasStock, prod) => hasStock && this.hasStockFor(prod), true);
  }

  get subtotal() {
    return this.shop_products.reduce((total, prod) => (total += prod.product.price.value.price_excl_vat * prod.qty), 0);
  }

  get count() {
    return this.cartService.cart ? this.cartService.cart.count : 0;
  }

  get outOfStockCount() {
    return this.shop_products.reduce((total, prod) => (this.hasStockFor(prod) ? total : total + 1), 0);
  }

  hasStockFor(prod: CartProduct) {
    return prod.product.stock && prod.qty <= prod.product.stock;
  }

  hasInsufficientStockFor(prod: CartProduct) {
    return prod.product.stock && prod.qty > prod.product.stock;
  }

  hasNoStockFor(prod: CartProduct) {
    return prod.product.stock && prod.product.stock === 0;
  }

  stockAvailabilityLabelFor(prod: CartProduct) {
    return this.hasNoStockFor(prod)
      ? 'Out of Stock'
      : this.hasInsufficientStockFor(prod)
      ? 'Max avail: ' + prod.product.stock
      : '';
  }

  getProductImage(product: IProduct): string | boolean {
    return product && product.image ? this.productService.getProductImage(product.id, ImageSize.small) : false;
  }

  update(prod: IProduct, qty: number) {
    let q = Math.abs(+qty);
    let rem = 0;
    if (prod.batch_size) {
      rem = q % +prod.batch_size;
      if (rem !== 0) {
        this.alert = new Alert(
          AlertType.danger,
          `The quantity should be a multiple of the product batch size (${prod.batch_size})`
        );
        this._valid = false;
        q = q - rem;
      }
    }
    if (+qty > prod.order_size.max) {
      this.alert = new Alert(
        AlertType.danger,
        `The quantity can not exceed the maximum order size (${prod.order_size.max}) for the product`
      );
      this._valid = false;
      q = prod.order_size ? prod.order_size.max : +qty;
    }
    if (+qty < prod.order_size.min) {
      this.alert = new Alert(
        AlertType.danger,
        `The quantity can not be less than the minimum order size (${prod.order_size.min}) for the product`
      );
      this._valid = false;
      q = prod.order_size ? prod.order_size.min : +qty;
    }
    if (this.alert && +qty <= prod.order_size.max && +qty >= prod.order_size.min && rem === 0) {
      this.alert.showAlert = false;
      this._valid = true;
    }

    this.cartService.updateItem(+prod.id, q);
  }

  remove(product: IProduct) {
    this.cartService.remove(+product.id);
  }

  removeAllOutOfStockItems() {
    this.shop_products.forEach(({ product }) => {
      this.remove(product);
    });
  }

  get valid() {
    return this._valid && this.hasStock && (this.hasCheckouts || this.hasQuotes);
  }
}
