import { Injectable } from '@angular/core';
import { Dictionary } from 'core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

enum AdImgSize {
  small = 'small',
  large = 'large'
}

export enum AdPosition {
  leaderboard = 'leaderboard',
  sideblock = 'sideblock'
}

enum Customer {
  mjs,
  milchstef,
  meatsmiths,
  ss,
  ags,
  demper
}

export interface AdLink {
  img: (size: AdImgSize) => string;
  url: {
    external: boolean;
    url: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AdService {
  private rolling_leaderboard: Observable<AdLink>;
  private rolling_sideblock: Observable<AdLink>;
  private ad_map: Dictionary<[string, boolean]> = new Dictionary();

  private ad_resolver: (
    position: AdPosition
  ) => (customer: string) => (size: AdImgSize) => string = position => customer => size => {
    let src = `https://downloads.agrichecker.com/ads/${customer}/${position}.png`;
    if (size === AdImgSize.small) {
      src = `https://downloads.agrichecker.com/ads/${customer}/sm/${position}.png`;
    }
    return src;
  };

  /***
   * Sample customer without replacement
   */
  private sample(beat: number): string {
    const ix = Math.floor((Math.random() * Object.keys(Customer).length) / 2);
    return Customer[ix];
  }

  constructor() {
    this.ad_map.add(Customer.mjs.toString(), ['https://www.jerseysteak.co.za', true]);
    this.ad_map.add(Customer.milchstef.toString(), ['http://milchstef.co.za', true]);
    this.ad_map.add(Customer.meatsmiths.toString(), ['https://www.facebook.com/meyermeatsmiths/', true]);
    this.ad_map.add(Customer.ss.toString(), [
      '/products?category=Seed&price=0&price=175000&offset=0&limit=10&Water%20Requirement%20mm=0&Water%20Requirement%20mm=1500',
      false
    ]);
    this.ad_map.add(Customer.ags.toString(), ['https://www.facebook.com/AgritechSteelworks/', true]);
    this.ad_map.add(Customer.demper.toString(), ['https://demper.co.za/', true]);

    this.rolling_sideblock = timer(250).pipe(
      map(ix => {
        const sample = this.sample(ix);

        return {
          img: this.ad_resolver(AdPosition.sideblock)(sample),
          url: {
            external: this.ad_map.item(Customer[sample])[1],
            url: this.ad_map.item(Customer[sample])[0]
          }
        };
      })
    );

    this.rolling_leaderboard = timer(750).pipe(
      map(ix => {
        const sample = this.sample(ix);

        return {
          img: this.ad_resolver(AdPosition.leaderboard)(sample),
          url: {
            url: this.ad_map.item(Customer[sample])[0],
            external: this.ad_map.item(Customer[sample])[1]
          }
        };
      })
    );
  }

  get leaderboard(): Observable<AdLink> {
    return this.rolling_leaderboard;
  }

  get sideblock(): Observable<AdLink> {
    return this.rolling_sideblock;
  }
}
