<form [formGroup]="registerForm" (ngSubmit)="save()">
  <div class="row justify-content-center">
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label class="headings">First Name:</label>
        <input class="form-control" formControlName="name" placeholder="e.g. John" required />
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
          <div *ngIf="registerForm.get('name').invalid">
            {{ errors.REQUIRED }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">Last Name:</label>
        <input class="form-control" formControlName="surname" placeholder="e.g. Smith" required />
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
          <div *ngIf="registerForm.get('surname').invalid">
            {{ errors.REQUIRED }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">Email Address:</label>
        <input class="form-control" formControlName="email" placeholder="e.g. name@domain.com" required />
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
          <div *ngIf="!registerForm.get('email').value">
            {{ errors.REQUIRED }}
          </div>
          <div *ngIf="registerForm.get('email').invalid && registerForm.get('email').value">
            {{ errors.INVALID_EMAIL }}
          </div>
        </div>
      </div>
      <div formGroupName="passwords">
        <div class="form-group">
          <label class="headings">Password:</label>
          <input
            type="password"
            minlength="6"
            class="form-control"
            formControlName="password"
            placeholder="e.g. xxxxxxxxx"
            required
          />
          <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
            <div *ngIf="registerForm.get('passwords.password').hasError('required')">
              {{ errors.REQUIRED }}
            </div>
            <div *ngIf="registerForm.get('passwords.password').hasError('minlength')">
              {{ errors.SHORT_PASSWORD }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="headings">Confirm Password:</label>
          <input type="password" class="form-control" formControlName="confirmPassword" placeholder="e.g. xxxxxxxxx" />
          <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="text-danger">
            <div *ngIf="registerForm.get('passwords.confirmPassword').hasError('required')">
              {{ errors.REQUIRED }}
            </div>
            <div
              *ngIf="
                !registerForm.get('passwords.confirmPassword').hasError('required') &&
                registerForm.get('passwords').hasError('matchingPasswords')
              "
            >
              {{ errors.NON_MATCHING_PASSWORDS }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">
          <input type="checkbox" formControlName="accepted" /> I've read and accepted the
          <a (click)="open()"><span class="ac-label ac-link">Terms And Conditions of Agrichecker</span></a></label
        >
        <div *ngIf="registerForm.invalid && (registerForm.dirty || registerForm.touched)" class="row text-danger">
          <div *ngIf="registerForm.get('accepted').invalid" class="col-sm">
            {{ errors.UNACCEPTED_TERMS }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="headings">
          <input formControlName="subscribe" type="checkbox" /> I would like to receive regular information and
          promotions from Agrichecker
        </label>
      </div>
      <div class="text-center">
        <ngb-alert *ngIf="alert?.showAlert" (close)="alert.showAlert = !alert.showAlert" [type]="alert.type">
          {{ alert.message }}</ngb-alert
        >
        <button type="submit" button [disabled]="registerForm.invalid" class="btn ac-btn-primary mt-2 col-md-4 col-6">
          CREATE ACCOUNT
        </button>
      </div>
    </div>
  </div>
</form>
