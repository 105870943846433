import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert, AlertType, LoginResult, ERROR } from 'core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../authorization/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  alert: Alert;
  sub: Subscription;
  readonly MERCHANT_URL = environment.merchant_url;
  is_merchant = false;
  private readonly errors = ERROR;

  @ViewChild('content', { static: true }) public content: TemplateRef<any>;

  loginForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required]
  });

  modalForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])]
  });

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('forgot_password')) {
        this.open(this.content);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
  }

  onModalSubmit() {
    this.sub = this.auth.forgotPassword(this.modalForm.controls['email'].value).subscribe(
      _ => {
        this.router.navigate(['/home']);
      },
      err => {
        this.alert = new Alert(AlertType.danger, 'Something happened and your password has not been reset');
        console.log(err);
      }
    );
    this.modalService.dismissAll();
  }

  signin() {
    this.auth.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value).subscribe(
      (res: LoginResult) => {
        if (!res.authorised) {
          if (res.is_merchant) {
            this.alert = new Alert(AlertType.danger, 'Please sign in on the merchant dashboard ');
            this.is_merchant = !!res.is_merchant;
          } else {
            this.alert = new Alert(AlertType.danger, 'Something happened and you have not been signed in');
          }
        } else {
          const url = this.auth.redirectUrl ? this.auth.redirectUrl : '/home';
          this.router.navigate([url]);
        }
      },
      err => {
        this.alert = new Alert(AlertType.danger, this.errors.USERNAME_PASSWORD_ERROR);
      }
    );
  }
}
