import { Injectable } from '@angular/core';
import { PaymentFormData, PaymentInfo } from 'core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private readonly paymentUrl = 'https://pay.ozow.com';
  constructor() {}

  pay(payment_info: PaymentInfo) {
    const form = this.create_form(payment_info);
    window.document.body.appendChild(form);
    form.submit();
  }

  private create_form(data: PaymentFormData): HTMLFormElement {
    const form = window.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', this.paymentUrl);

    //use _self to redirect in same tab, _blank to open in new tab
    form.setAttribute('target', '_self');

    //Add all the data to be posted as Hidden elements
    for (const [key, value] of Object.entries(data)) {
      form.appendChild(this.createHiddenElement(key, value ? value.toString() : ''));
    }
    return form;
  }

  private createHiddenElement(name: string, value: string): HTMLInputElement {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }
}
