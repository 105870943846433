import { Injectable } from '@angular/core';
import { ApiService, CustomerRegistrationRequest, MerchantRegistrationRequest, Namespace, Endpoints } from 'core';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private api: ApiService) {}

  registerCustomer(customer: CustomerRegistrationRequest) {
    return this.api.post(Namespace.ac, Endpoints.customer_registration, customer);
  }

  registerMerchant(merchant: MerchantRegistrationRequest) {
    return this.api.post(Namespace.ac, Endpoints.merchant_registration, merchant);
  }
}
