import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'core';
import { MomentModule } from 'ngx-moment';
import { MerchantInfoComponent } from './merchant-info/merchant-info.component';
import { OrderAddressComponent } from './order-address/order-address.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrderComponent } from './order/order.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { ConfirmDialogComponent } from 'core';

@NgModule({
  declarations: [
    OrderConfirmationComponent,
    OrderSummaryComponent,
    MerchantInfoComponent,
    OrderAddressComponent,
    OrderListComponent,
    OrderComponent
  ],
  entryComponents: [MerchantInfoComponent, ConfirmDialogComponent],
  imports: [
    MomentModule,
    CommonModule,
    CoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-1Ii54pEm-3N84LeNYnqWtoRd0THpOPw',
      libraries: ['places']
    }),
    NgbAlertModule,
    OrdersRoutingModule
  ],
  exports: [OrderListComponent, OrderAddressComponent]
})
export class OrdersModule {}
