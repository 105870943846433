<div style="overflow-x:auto;">
  <table class="table table-bordered order-list-table">
    <thead>
      <tr>
        <th>ORDER NUMBER:</th>
        <th>DATE:</th>
        <th>MERCHANT NAME:</th>
        <th>TOTAL:</th>
        <th>VAT:</th>
        <th>PAYMENT METHOD:</th>
        <th>STATUS:</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ order?.order_number }}</td>
        <td>{{ order?.date_created }}</td>
        <td>
          <a
            ><span class="link" (click)="open()">{{ order?.merchant?.name }}</span></a
          >
        </td>
        <td>{{ order?.order?.total | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
        <td>{{ order?.order?.total_vat | currency: 'ZAR ':'symbol-narrow':'1.2-2' }}</td>
        <td>{{ order?.payment_method }}</td>
        <td>{{ order?.status }}</td>
      </tr>
    </tbody>
  </table>
</div>
