<div class="container mb-5">
  <div *ngIf="numberOfOrders > 0; else noOrders" class="mb-3">
    <div class="row">
      <div class="col-md" style="overflow-x:auto;">
        <table class="table table-bordered table-hover order-list-table">
          <thead>
            <tr>
              <th>ORDER NUMBER:</th>
              <th>DATE:</th>
              <th>MERCHANT NAME:</th>
              <th>TOTAL:</th>
              <th>VAT:</th>
              <th>PAYMENT METHOD:</th>
              <th>STATUS:</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let order of orders"
              class="cursor-pointer"
              [ngClass]="{ 'table-active': order.id === selected_order?.id }"
              (click)="selectOrder(order)"
            >
              <td>{{ order?.order_number }}</td>
              <td>{{ order?.date_created }}</td>
              <td>
                <a
                  ><span class="link" (click)="open(order)">{{ order?.merchant?.name }}</span></a
                >
              </td>
              <td>{{ order?.order?.total | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
              <td>{{ order?.order?.total_vat | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
              <td>{{ order?.payment_method }}</td>
              <td class="text-capitalize">{{ order?.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>

  <ng-template #noOrders>
    <div class="row justify-content-center" style="margin-bottom: 15%; margin-top: 15%;">
      <label>You have not yet placed an order, start <a routerLink="/">buying</a>.</label>
    </div>
  </ng-template>
</div>
