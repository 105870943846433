<div class="row">
  <div class="col">
    <div class="shadow bg-white rounded">
      <ngb-carousel *ngIf="slides" [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let slide of slides">
          <div *ngIf="slide.external; else internal">
            <a [href]="slide.url" target="_blank"><img [src]="slide.image" class="ac-slide"/></a>
          </div>
          <ng-template #internal>
            <a [routerLink]="slide.url"><img [src]="slide.image" class="ac-slide"/></a>
          </ng-template>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>
