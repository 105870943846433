import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private loggedIn = false;
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.auth.isLoggedIn.subscribe(login => {
      this.loggedIn = typeof login === 'boolean' ? <boolean>login : false;
    });

    if (this.loggedIn) {
      return true;
    } else {
      this.auth.redirectUrl = state.url;
      this.router.navigate(['/signin']);
      return false;
    }
  }
}
