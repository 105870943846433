import { NgModule } from '@angular/core';
import { AddressComponent } from './address/address.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { CoreModule } from 'core';
import { CollectionLocationsComponent } from './collection-locations/collection-locations.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [AddressComponent, CollectionLocationsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbAlertModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-1Ii54pEm-3N84LeNYnqWtoRd0THpOPw',
      libraries: ['places']
    }),
    CoreModule,
    MatBottomSheetModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [AddressComponent, CollectionLocationsComponent]
})
export class ShopCoreModule {}
